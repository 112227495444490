<script>
import NewsCard from "./news/NewsCard";
import QuoteCard from "./QuoteCard";

import {bus} from "../../router/bus";

export default {
  
  components: {
    NewsCard,
    QuoteCard
  },
  
  data () {
    return {
      news: [],
    }
  },
  
  mounted() {
    this.getNews()
  },
  
  methods: {
    getNews() {
      this.$postapi(this.$address + this.$getters.getServiceNews.uri, {
        method: this.$getters.getServiceNews.name,
      }).then(data => {
        if (data.error === 0) {
          this.news.push(JSON.parse(data.message))
          this.sortNews()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    sortNews() {
      this.news[0].sort((a, b) => new Date(a.mdate) - new Date(b.mdate));
    }
  }
}
</script>

<template>
  <div class="info-block g-row">
    
    <div class="info-block__news g-col-lg-8 g-col-md-8 g-col-sm-8 g-col-xs-2" v-if="news.length > 0">
      <div class="info-block__news-title">Vedexx Новости</div>
      
      <hr class="info-block__news-line"/>
      
      <div
        class="info-block__news-cards"
        v-for="report in news[0].slice(-3).reverse()"
        :key="report.id"
      >
      <NewsCard :news="report"/>
      </div>
  
      <router-link
        class="info-block__news-show-more"
        to="/NewsList"
      >
        <span>Показать ещё</span>
      </router-link>
    </div>
    
    <div class="info-block__quote g-col-lg-4 g-col-md-4 g-col-sm-8 g-col-xs-2">
      <QuoteCard class="info-block__quote"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.info-block {
  display: flex;
  
  &__news {
    
    @media (max-width: $tablet-width) {
      padding-bottom: $size-20;
    }
  
    &-title {
      @include font-headline-1;
      
      color: #301E06;
  
      @media (max-width: 600px) {
        font-size: 24px;
      }
    }
    
    &-line {
      margin: 8px 0 20px;
      color: #000000;
    }
    
    &-cards {
      width: auto;
      margin-bottom: $size-24;
      position: relative;
      background: #FFFFFF;
      box-shadow: 1.97853px 1.97853px 8.9034px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
    
    &-show-more {
      @include font-description-semibold-0;
      
      display: flex;
      width: 100%;
      color: black;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: center;
      cursor: pointer;
      z-index: 2;
      @include right-gap;
      
      &:hover {
        text-decoration: underline;
      }
      
      &:after {
        content: '';
        text-align: center;
        line-height: 10px;
        background: url('../../assets/img/icons/mdi/arrow-down-2-duotone.svg') no-repeat;
        background-size: 100%;
        width: 20px;
        height: 19px;
      }
    }
  }
  
  &__quote {
    display: block;
    margin-top: 20px;
  
    @media (max-width: $tablet-width) {
      margin-bottom: $size-20;
    }
  }
}


@media (max-width: $tablet-width) {
  .info-block > .info-block__quote {
    order: -1;
  }
}

a {
  text-decoration: none;
  color: black;
}
</style>
