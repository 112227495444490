export const filter_methods_including = {
  methods: {
    checkAll(item, data){
      return this.checkAllWithPorts(item,data)
    },

    checkAllWithPorts(item, data) {
      let dataParams = Object.keys(data)
      let res = true

      for (let i = 0; i < dataParams.length; i++) {
        if (data[dataParams[i]].length > 0 || dataParams[i] === 'price_limit') {
          if (!eval('this.check_' + dataParams[i] + '(item, data[dataParams[i]])')) res = false
        }
      }

      return res
    },

    check_price_limit (item, limit) {
      return (item.total <= limit)
    },

    check_is_not_sea (item, limit) {
      return (limit.includes(item.is_sea))
    },

    check_is_soc (item, limit) {
      return (limit.includes(parseInt(item.is_soc)))
    },

    check_is_hot (item, limit) {
      return (limit.includes(parseInt(item.is_hot)))
    },

    check_is_unverified(item, limit) {
      return (limit.includes(parseInt(item.is_unverified)))
    },

    check_is_not_direct_route (item, limit) {
      return (limit.includes(parseInt(item.is_not_direct_route)))
    },

    check_is_ete (item, limit) {
      return (limit.includes(parseInt(item.is_ete)))
    },

    check_is_not_vtt (item, limit) {
      return (limit.includes(parseInt(item.is_not_vtt)))
    },

    check_selected_port(item, limit) {
      return (limit.includes(item.terminal_id) || limit.includes(parseInt(item.across_place_name_ext_id)))
    },

    check_is_agent(item, limit) {
      return (limit.includes(parseInt(item.is_agent)))
    },

    check_is_kfk(item, limit) {
      return (limit.includes(parseInt(item.is_kfk)))
    },

    check_selected_station (item, stations) {
      return (stations.includes(item.station_id))
    },

    check_selected_client (item, clients) {
      if (item.client_list.length > 0) {
        return item.client_list.some(obj => clients.includes(obj.client_id));
      } else if (item.client_list.length == 0 && clients.includes(111111)) return true;
      else return false
    },

    check_is_schedule (item, limit) {
      return (limit.includes(parseInt(item.is_schedule)))
    },

    check_is_direct (item, limit) {
      return (limit.includes(parseInt(item.is_direct)))// && [0].includes(parseInt(item.is_ete)) )
    },

    check_is_lack_of_empty_equipment_date (item, limit) {
      return (limit.includes(item.lack_of_empty_equipment_type))
    },

    check_selected_contractor (item, transporters) {
      return ((transporters.includes(item.line_id) && item.is_hide_line != 1)
        || (transporters.includes('vedexx') && item.is_hide_line == 1)
        || (transporters.includes(item.line_id) && item.is_hide_line == 1 && localStorage.getItem('client_id') == -1))
    },
  }
}