<script>
import { bus } from "@/router/bus";

export default {
  name: "RateSortPanel",

  props: {
    rate_list_length: { type: Number, default: 0 }
  },

  data() {
    return {
      sort_by: 0,
    }
  },

  methods: {
    setsort(val) {
      this.sort_by = val
      this.setSortParam()
    },

    setSortParam() {
      bus.$emit("sortrates", {type: this.sort_by});
    },
  }
}
</script>

<template>
  <div class="rate-filter__sort">
    <div class="rate-filter__sort__count">
    Найдено {{ rate_list_length }}
    </div>
    <div class="rate-filter__sort-panel">
      <img alt="sort" src="../../assets/img/sort.svg">
      <div 
        @click="setsort(1)"
        class="rate-filter__sort-panel-tag"
        :class="{ checked: sort_by === 1 }"
      >
        по скорости
      </div>
      <div 
        @click="setsort(0)"
        class="rate-filter__sort-panel-tag"
        :class="{ checked: sort_by === 0 }"
      >
        по цене
      </div>
      <div 
        @click="setsort(3)"
        class="rate-filter__sort-panel-tag"
        :class="{ checked: sort_by === 3 }"
      >
        ближайший выход
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rate-filter {
  &__sort {
      display: flex;
      margin: 0 0 24px 0;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $mobile-width) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &__count {
        color: var(--grey-70);
        @include font-description-semibold-0;
      }
    
      &-panel {
        display: flex;
        gap: 12px;
        align-items: center;

        @media (max-width: $mobile-width) {
          flex-direction: column;
          align-items: flex-start;
        }

        > img {
          width: 24px;
          height: 24px;
        }

      &-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      &-tag {
        cursor: pointer;
        @include font-description-semibold-0;
        color: var(--grey-70);

        &.checked {
          color: var(--blue-70);
        }
      }
    }
  }
}
</style>