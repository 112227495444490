<script>
import CVTBAutoComplete from "@/components/common/CVTBAutoComplete";
import {complex_spec} from "@/components/jsons/complex-rates-params";
import {bus} from "@/router/bus";

export default {
  name: 'FindComplexRatePanel',
  
  components:{
    CVTBAutoComplete,
//  CVTBDateInput,
  },
  
  data: () => ({
    searchPlaceFrom: null,
    searchPlaceTo: null,
    searchLine: null,
    lang: 'en',
    
    searchType: 'fn_getratelist_complex',
    historyData: [],
    historyString: [],
    
    name: complex_spec.name,
    elements: complex_spec,
    selected_line: [],
    places_from: [],
    linelist: [],
    formdata: [],

    lineFocused: false,
    placesFocused: false,

    selectedLines: []
  }),
  
  mounted(){
    this.getReferenceList('LgstLineList')
    this.getSearchParamsHistory()
  },
  
  watch: {
    searchPlaceFrom(str) {
      if (!str) {
        return
      }
      this.checkLang(str)
    },
  },

  computed: {
    filteredLinelist() {
      if(this.selected_line.length > 1){
        if (this.selected_line.includes('0')) {
          return this.linelist.slice(1)
        }
      }
      return this.linelist
    },
  },

  methods: {
    checkLang(str) {
      clearTimeout(this._timerId)
      
      if (str.length > 0) {
        if (/[а-я]/i.test(str) == true) {
          this.lang = "ru"
        } else {
          this.lang = "en"
        }
      }
      
      this._timerId = setTimeout(() => {
        this.searchPlaceFrom = str
      }, 10)
    },
    
    getSearchParamsHistory() {
      this.$postapi(this.$address + this.$getters.getSearchParamsHistory.uri, {
        method: this.$getters.getSearchParamsHistory.name,
        search_type: this.searchType,
        token: this.$_getsetting('token')
      }).then(data => {
        if (data.error == 0) {
          this.historyString =JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
          this.historyData = JSON.parse(this.historyString[0].result)
        }
      })
    },
    
    getReferenceList(refName) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: this.$references[refName].name
      }).then(data => {
        if (data.error == 0) {
          switch (refName) {
            case 'lgst_place_from_wg':
              this.places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'LgstLineList':
              this.linelist = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
              this.sortLines()
              this.selected_line.push('0')
              break
          }
        } else {
          return []
        }
      });
    },
    
    OnChange(data) {
      this.formdata[data.element] = data.value
    },
    
    OnDateChange(data) {
      this.formdata[data.element] = data.value
    },
    
    extractvalues(element){
      let result = []
      for (let i = 0; i < element.length; i++){
        result.push(parseInt(element[i]['id']))
      }
      return result.join()
    },
    
    extractdatevalue(){
      if (this.formdata['on_date'] != undefined)
        return this.formdata['on_date']
      else
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    
    sendRequest() {
      if ((!this.formdata['place_from'] || this.formdata['place_from'].length == 0 || !this.formdata['place_to'] || this.formdata['place_to'] == 0) || this.selected_line.length === 0) {
      bus.$emit("show_notify", {
        color: "error",
        notifytext: 'Заполните все поля'
      });
      
      return
    }

      bus.$emit("getcomplexrates", {
        on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        place_to: this.extractvalues(this.formdata['place_to']),
        place_from: this.extractvalues(this.formdata['place_from']),
        // place_from: this.formdata.place_from.join(),
        line_id: this.selected_line.join(),
        unit_code: '6,8',
        client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99 ,
      });
    },
    
    search(){
      bus.$emit('show_complex_rate')
      setTimeout(this.sendRequest, 500);
    },

    removeItem(item) {
      this.selected_line = this.selected_line.filter(i => i !== item.id);
    },

    getResultItems() {
      this.selectedLines = this.linelist.filter(line => this.selected_line.includes(line.id))

      if(this.selected_line.length > 1){
        this.selectedLines = this.selectedLines.filter(line => line.id !== '0')
      }
    },

    sortLines() {
      this.linelist.sort((a, b) => a.name.localeCompare(b.name))
    },
  },
}
</script>

<template>
  <v-form
    class="complex-rate-params g-row"
    @submit.prevent="search"
  >

    <CVTBAutoComplete
      class="complex-rate-params__autocomplete"
      :element="elements.place_from_element"
      @selected="OnChange"
      :itemsLimit="20"
    />

    <CVTBAutoComplete
      class="complex-rate-params__autocomplete"
      :element="elements.place_to_element"
      @selected="OnChange"
      :itemsLimit="5"
    />
    <div class="complex-rate-params__autocomplete complex-rate-line">
      <v-autocomplete
        v-model="selected_line"
        :items="selectedLines.length < 15 ? filteredLinelist : selectedLines"
        chips
        clearable
        deletable-chips
        solo
        flat
        small-chips
        multiple
        color="var(--main-orange)"
        item-text="name"
        label="Перевозчик"
        item-value="id"
        :search-input.sync="searchLine"
        @focus="lineFocused = true"
        @blur="lineFocused = false"
        @input="searchLine = ''"
        @change="getResultItems()"
        autocorrect="off"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            v-if="lineFocused || data.index < 3"
            :key="data.item.id"
            small
            close
            @click:close="removeItem(data.item)"
          >
            {{ data.item.name }}
          </v-chip>
          <v-chip
            v-if="!lineFocused && data.index === 3"
            @click:close="removeItem(data.item)"
            small
          >
            +{{ selectedLines.length - 3 }}
          </v-chip>
        </template>
      </v-autocomplete>

      <div v-if="selectedLines.length < 15" class="complex-rate-line__description">Выберите не более 15</div>
      <div v-else class="complex-rate-line__description">Выбрано максимальное количество</div>
    </div>

    <v-btn
      class="complex-rate-params__btn g-col-md-3 g-col-lg-2"
      color="var(--main-orange)"
      dark
      depressed
      rounded
      large
      type="submit"
    >
      <img class="complex-rate-params__btn-img" src="../../assets/img/icons/mdi/mdi-magnify.svg" alt="">
      Найти варианты
    </v-btn>
  </v-form>
</template>

<style scoped lang="scss">

.complex-rate-params{
  margin: 0 auto;
  //align-items: center;
  justify-content: center;
  align-content: center;
  
  &__autocomplete {
    width: 24% !important;
    max-width: 250px;
    border-radius: 2px;
    padding: 2px 0;
    min-height: 52px !important;
    
    &:not(:last-child) {
      @include right-gap;
    }
    
    @media (max-width: $mobile-width) {
      width: 100% !important;
      max-width: 100%;
      margin: -21px 0;
      padding: 0;
      min-height: 52px !important;

      &:not(:last-child) {
       margin: unset;
      }
    }
  }
  
  &__btn {
    height: 52px !important;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 0;

    @include font-body-bold-3;
  }
  
  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 11px;
    margin-bottom: 11px;
  }
}
.complex-rate-params__btn-img{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.complex-rate-line{
  &__description{
    position: relative;
    bottom: 18px;
    color: #FFF;
    z-index: -1;
    @include font-description-1;
  }

  &:has(.v-input--is-focused){
    .complex-rate-line__description{
      display: none;
    }
  }
}
</style>
