export const video_slides = [
  {
    id:  7,
    title: "",
    subtitle: "",
    videoUrl: "webinar.mp4",
    poster: "video_cover-wn.webp",
    coverUrl: "video_cover-wn.webp"
  },
  {
    id:  6,
    title: "Vedexx White Night",
    subtitle: "Презентация системы Vedexx",
    videoUrl: "White_night.mp4",
    poster: "video_cover-wn.webp",
    coverUrl: "video_cover-wn.webp"
  },
  {
    id:  1,
    title: "IT-логистика с Vedexx",
    subtitle: "Освободи своё время, логистику оставь на Vedexx",
    videoUrl: "Vedexx_video1.mp4",
    poster: "video_cover1.webp",
    coverUrl: "video_cover1.webp"
  },
  {
    id:  2,
    title: "TransRussia 2023",
    subtitle: "Участие Vedexx на выставке транспортно-логистических услуг",
    videoUrl: "exhibition.mp4",
    poster: "video_cover-exhibition.webp",
    coverUrl: "video_cover-exhibition.webp"
  },
  {
    id:  3,
    title: "IT-логистика с Vedexx",
    subtitle: "Доминируй на рынке перевозок и ВЭД",
    videoUrl: "Vedexx_video2.mp4",
    poster: "video_cover2.webp",
    coverUrl: "video_cover2.webp"
  },
  {
    id:  4,
    title: "Конференция ТПП",
    subtitle: "Презентация Vedexx на Санкт-Петербургской Торгово-Промышленной палате",
    videoUrl: "TPP.mp4",
    poster: "video_cover-tpp.png",
    coverUrl: "video_cover-tpp.png"
  },
  {
    id:  5,
    title: "IT-логистика с Vedexx",
    subtitle: "Что может Vedexx за 5 секунд",
    videoUrl: "Vedexx_video3.mp4",
    poster: "video_cover3.webp",
    coverUrl: "video_cover3.webp"
  },
]