<script>
export default {
  name: 'MediaArticleCard',
  
  props: {
    media: {
      type: [Array, Object],
      required: true
    }
  },
  
  data() {
    return {
      showFullScreen: false
    }
  },
  
  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },
  }
}
</script>

<template>
  <div class="media">
    <div class="media-card" @click="showFullScreen = true">
      <div class="media-card__header">
        <div class="media-card__header-logo">
          <img
            :src="`/media-images/${media.logo_url}`"
            :title="media.media_name"
            :alt="media.media_name"
          >
        </div>
        <div class="media-card__header-date">
          {{media.date}}
        </div>
      </div>
  
      <div class="media-card__news-screen">
        <img
          v-if="windowSize > 800"
          :src="`/media-images/${media.screen_url}`"
          :title="media.media_name"
          :alt="media.media_name"
        >
        <img
          v-else
          :src="`/media-images/${media.mobile_logo}`"
          :title="media.media_name"
          :alt="media.media_name"
        >
      </div>
    </div>
  
    <div class="media__link">
      <a target="_blank" :href="media.source_url">
        Читать&nbsp;источник
      </a>
    </div>
    
    <v-dialog
      v-model="showFullScreen"
      persistent
      @click:outside="showFullScreen = false"
      transition="dialog-bottom-transition"
      class="media__dialog"
      :width="windowSize > 800 ? '70%' : '100%'"
      :height="windowSize > 800 ? '80%' : '100%'"
    >
      <v-card v-if="showFullScreen === true">
        <v-card-title class="media__dialog__title row justify-space-between">
          {{media.media_name}}
          <v-btn
            color="black"
            @click="showFullScreen = false"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <a target="_blank" :href="media.source_url">
          <img
            class="media__dialog__full-img"
            :src="`/media-images/${media.full_screen_url}`"
            :title="media.media_name"
            :alt="media.media_name"
          >
        </a>
        <v-divider class="mt-6"></v-divider>
        <v-card-actions class="py-4">
          Источник: <a target="_blank" :href="media.source_url"> {{media.source_url}}</a>
          <v-spacer/>
          <v-btn
            color="var(--main-bg-color)"
            text
            @click="showFullScreen = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">

.media {
  height: 100%;
  
  > a {
    text-decoration: none !important;
  }
  
  &-card {
    cursor: pointer;
    text-decoration: none !important;
    padding: 20px;
    height: calc(100% - 20px);
    border-radius: 3.814px;
    background: #FFF;
    box-shadow: 1.793276071548462px 0px 14.346208572387695px 0px rgba(0, 0, 0, 0.16);
    transition-duration: 0.3s;
    
    @media (max-width: 600px) {
      padding: 10px;
    }
  
    &:hover {
      transform: translateY(-2px) scale(1.01);
    }
  
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 50px;
      margin-bottom: 10px;
      
      &-logo {
        max-width: 70%;
        max-height: 40px;
        
        > img {
          max-width: 100%;
          max-height: 40px;
        }
      }
      
      &-date {
        color: rgba(0, 0, 0, 0.60);
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.807px;
        text-decoration: none !important;
      }
    }
    
    &__news-screen {
      width: 100%;
      cursor: zoom-in;
      
      > img {
        width: 100%;
        height: auto;
  
        @media (max-width: $mobile-width) {
          max-height: 280px;
          object-fit: cover;
          object-position: 0 0;
        }
      }
    }
  }
  
  &__link {
    margin-top: $size-12;
    
    > a {
      @include font-subtitle-medium;
      
      color: #000;
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 25px;
      
      &:hover {
        text-decoration: underline;
      }
      
      &:focus,
      &:active {
        color: var(--bg-dark);
      }
      
      &:after {
        content: '';
        text-align: center;
        padding: 7px 3px 0;
        background: url('../../../assets/img/icons/mdi/arrow-right-thin.svg') no-repeat;
        width: 20px;
        height: 15px;
        margin-left: 5px;
      }
      
      @media (max-width: $mobile-width) {
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
  
  &__dialog {
    
    &__title {
      font-size: 22px;
      font-weight: bold !important;
      margin: 30px 10px;
  
      @media (max-width: $mobile-width) {
        font-size: 16px;
        margin: 0;
      }
    }
  
    &__full-img {
      max-width: 95%;
      margin: 10px auto;
      display: flex;
    
      > img {
        max-width: 100%;
      }
    }
  }
}
</style>
