<script>
import FindRatePanel from "../find-rate/FindRateForm";
import Vue from 'vue';
import RateList from "@/components/rate-list/RateListNew.vue";
import FindComplexRatePanel from "../find-rate/FindComplexRateForm";
import RateComplexList from "../complex-rate/RateComplexList";
import BestOffers from "@/components/main-page/best-offers/BestOffers.vue"

import { bus } from "@/router/bus";

export default {
  name: "RateListPage",
  components : {
    FindRatePanel,
    RateList,
    RateComplexList,
    FindComplexRatePanel,
    BestOffers,
  },

  data: () => ({
    tab: null,
    searchOneRateClicked: false,
    searchComplexRateClicked: false,
    searchAlarmRateClicked: false,
    cId: null,
    windowWidth: window.innerWidth,

    authStatus: !!localStorage.getItem('is_auth'),

    banners: [],
    searchParams: {},
    bannerImages: [],
    loadedBannerImages: [],
  }),
  
  created() {
    this.$_setsetting('routerState', { path: '/Main', query: {}})
    
    bus.$on("show_one_rate", (params) => {
      this.searchParams = params
      this.searchComplexRateClicked = false
      this.searchOneRateClicked = true
    });
    
    bus.$on("show_complex_rate", () => {
      this.searchOneRateClicked = false
      this.searchComplexRateClicked = true
      bus.$emit("showRateList", true);
    });
    
    bus.$on("hide_rate", () => {
      this.searchOneRateClicked = false
      this.searchComplexRateClicked = false
      bus.$emit("showRateList", false);
    });

    bus.$on("auth-update", () => {
      this.authStatus = !!this.$_getsetting('is_auth')
      this.$refs.findRates.getSearchParamsHistory()
    })
  },
  
  computed: {
    isAuth() {
      return this.authStatus;
    },
  },
  
  mounted() {
    this.getsearchParams()

    if (!this.isAuth || this.windowWidth < 744) {
      this.tab = 0
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    
    if (this.$_getsetting("client_id")) {
      this.cId = this.$_getsetting("client_id");
    } else {
      this.cId = -99
    }

    this.getTripwires()
  },
  
  methods: {
    getsearchParams() {
      this.searchParams = Object.keys(this.$route.query).length != 0 ? { ...this.$route.query } : null;
      if (this.searchParams !== null) {
        if (this.searchParams.complex_rate === 'false') {
          this.searchComplexRateClicked = false
          this.searchOneRateClicked = true
        } else {
          if (this.isAuth === true) {
            this.searchOneRateClicked = false
            this.searchComplexRateClicked = true
            this.tab = 1
          } else {
            this.$router.replace({ path: this.$route.path });
          }
        }
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth
    },

    getTripwires() {
      this.$getapi(this.$api_content + '/api/tripwires')
        .then((data) => {
        if (data) {
          this.banners = data.data
          this.bannerImages = data.data.map(item => {
              return {
                img: this.$api_content + item.properties.images.image_big, 
                id: item.id
              }
            })
            this.preloadImages(this.bannerImages)
            this.loadedBannerImages = this.bannerImages
        } else {
          console.log(data.message)
        }
      });
    },

    preloadImages(imageUrls) {
      return Promise.all(
        imageUrls.map((url) => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = url.img
            img.onload = resolve
            img.onerror = reject
          })
        })
      )
    }
  },

  provide() {
    const tripwires = Vue.observable({
      banners: this.banners
    });

    const images = Vue.observable({
      tripwireBanners: this.loadedBannerImages
    });

    this.$watch(
      () => this.banners,
      (newBanners) => {
        tripwires.banners = newBanners;
      }
    );

    this.$watch(
      () => this.loadedBannerImages,
      (newImages) => {
        images.tripwireBanners = newImages;
      }
    );

    return {
      tripwires,
      tripwireImages: images
    };
  }
}
</script>

<template>
  <div class="main-page">
    <h1 class="main-page__ttl">
      <span>Контейнерная логистика онлайн</span>
      <br>
      <span>быстро, просто, достоверно</span>
     </h1>
    <div ref="searchRates" id="searchRates" class="main-page__tabs-container">
      <v-tabs
        v-if="isAuth && windowWidth > 743"
        ref="searchRates"
        id="searchRates"
        v-model="tab"
        class="main-page__tabs"
        :class="isAuth === true ? '' : 'main-page__tabs-unauth-width'"
        color="none"
        centered
        active-class="main-page__tab-active"
      >
        <v-tabs-slider style="display: none"/>
        
        <v-tab class="main-page__tab" v-if="windowWidth > 743">Один маршрут</v-tab>
        <v-tab class="main-page__tab" v-else>Маршрут</v-tab>
        <v-tab v-if="isAuth === true" class="main-page__tab main-page__tab-centered">Несколько</v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab" class="main-page__find-panel">
      <v-tab-item>
        <FindRatePanel class="main-page__find-rate" :class="{ 'main-page__find-rate-unauth': !isAuth || windowWidth < 743 }" ref="findRates"/>
      </v-tab-item>
      
      <v-tab-item v-if="isAuth === true">
        <FindComplexRatePanel
          :searchParams="searchParams"
          class="main-page__find-complex"
        />
      </v-tab-item>
    </v-tabs-items>

    <RateList
      v-if="searchOneRateClicked"
      :searchParams="searchParams"
      class="main-page__rate-list g-col-lg-12"
      :class="isAuth ? '' : 'main-page__rate-list-unauth'"
    />
    
    <RateComplexList
      v-if="searchComplexRateClicked"
      :searchParams="searchParams"
      class="main-page__rate-complex-list"
    />

    <BestOffers 
      v-if="!searchComplexRateClicked && !searchOneRateClicked"
      country="ch"
      class="main-page__best-offers"
    />
  </div>
</template>

<style scoped lang="scss">

.main-page {
  z-index: 100;
  margin-top: 80px;
  max-width: 100%;
  
  @media (max-width: $tablet-width) {
    margin-top: 88px;
  }
  
  &__ttl {
    margin-top: 20px;
    color: var(--grey-70);
    text-align: center;
    position: relative;
    @include font-title-2;

    > span:last-child {
    color: var(--orange-70);
  }

    @media (max-width: 1000px) {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.01em;
    }
    
    @media (max-width: $mobile-width) {
      margin-top: $size-40;
    }
  }
  
  &__tabs {
    margin: $size-48 auto 0;
    text-align: center;
    justify-content: center;
    width: 100%;
    
    @media (max-width: $tablet-width) {
      margin: $size-24 auto 0;
    }
    
    &-container {
      width: 100% !important;
      justify-content: center;
      display: flex;
      margin: 0 auto;
    }
    
    &-unauth-width {
      width: 100%;
      position: relative;
      justify-content: center;
    }
  }
  
  &__tab {
    color: var(--grey-70) !important;
    text-transform: none;
    height: 24px;
    letter-spacing: normal;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: var(--grey-20);
    border-radius: 4px;

    @include font-subtitle-medium;

    &-centered {
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
  
  &__tab-active {
    background: var(--blue-70);
    color: #FFFFFF !important;
    border: 1px solid #FFFFFF;
  }
  
  &__find-panel {
    position: relative;
    margin-top: $size-16;
    width: 100%;
    max-width: 1200px;
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);
  }
  
  &__find-rate {
    &-danger {
      @media (max-width: $mobile-width) {
        margin-top: 4px;
      }
    }
    
    &-unauth {      
      margin-top: 62px;

      @media (max-width: $mobile-width) {
        margin-top: 30px;
      }
    }
  }
  
  &__find-complex {
    margin-top: 12px;
    margin-bottom: 8px;

    @media (max-width: $mobile-width) {
      margin-top: 0;
    }
  }

  &__best-offers {
    margin-top: 100px;
    z-index: 120;
  }
  
  &__rate-list {
    position: relative;
    margin: 30px auto 0 auto;
    width: 100%;
    max-width: 1200px;

    @media (min-width: $desktop-width) {
      // width: 100vw;
      // left: 50%;
      // transform: translateX(-50%);
    }

    @media (max-width: $tablet-width) {
      height: auto;
      left: -32px;
      width: calc(100% + 64px);
      z-index: 3;
    }
    
    @media (max-width: $mobile-width) {
      left: -16px;
      width: calc(100% + 32px);
    }
  }
  
  &__rate-complex-list {
    position: relative;
    width: 100%;
    margin: 48px auto 0 auto;
    z-index: 3;
    
    @media (max-width: $tablet-width) {
      height: auto;
      left: -32px;
      margin-top: 185px;
      width: calc(100% + 64px);
    }
  }
}

.theme--light.v-tabs-items {
  background: none !important;
}

.v-slide-group__content .v-tabs-bar__content {
  display: flex;
  background: red;
}
</style>