<script>
export default {
  props: {
    options: { type: [Array, Object], required: true },
    value: { type: [String, Array, Object] },
    itemText: { type: String },
    label: { type: String },
    description: { type: String },
    rules: { type: Array, Object },
    tooltip: Boolean
  },

  data() {
    return {
      selectedValue: this.value || {},

      error: false,
      errorMessage: null,

      tooltipOptions: ["20 DV — вес до 24 тонн", 
        "20 DV Heavy — вес до 28 тонн", 
        "40 HС — вес до 28 тонн (легкий и объемный груз)" 
      ]
    };
  },

  watch: {
    value(newVal) {
      this.selectedValue = newVal;
    },
  },

  computed: {
    isSelected(option) {
      if (this.selectedValue.id) {
        return this.selectedValue.id === option.id
      } else return false
    }
  },

  methods: {
    selectOption(value) {
      this.selectedValue = value;
      this.$emit("input", this.selectedValue);
    },

    validate() {
      let isValid = true;
      this.errorMessage = null
      this.error = false
      for (const rule of this.rules) {
        const result = rule(this.selectedValue);
        if (result !== true) {
          this.error = true
          isValid = false;
          this.errorMessage = result;
          break;
        }
      }
      return isValid;
    }
  },
};
</script>

<template>
  <div class="radio-group">
    <div class="radio-group__head">
      <label>{{ label }}</label>
      <v-tooltip v-if="tooltip" top>
        <template v-slot:activator="{ on, attrs }">
          <img src="@/assets/img/icons/tooltip-icon.svg" v-bind="attrs" v-on="on">
        </template>
        <div class="tooltip">
          <p class="tooltip__title">Типы и размеры контейнеров:</p>
          <ul>
            <li v-for="(tooltip, idx) in tooltipOptions" :key="idx">
              {{ tooltip }}
            </li>
          </ul>
        </div>
      </v-tooltip>
    </div>
    <div :class="['radio-group__container', { 'radio-group__container-error': error }]">
      <div v-for="option in options" :key="option.value"
        :class="['radio-group__container__button', { selected: selectedValue && selectedValue.id === option.id }, { 'radio-group__container__button-error': error }]"
        @click="selectOption(option)">
        {{ option[itemText] }}
      </div>
    </div>
    <small class="radio-group__description">
      <span v-if="description">{{ description }}</span>
      <span class="error-message" v-if="errorMessage && error">{{ errorMessage }}</span>
    </small>
  </div>
</template>

<style scoped lang="scss">
.radio-group {
  display: block;

  &__head {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
  }

  label {
    color: var(--grey-70);
    padding-top: 0;
    line-height: 14px;
    @include font-description-1;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    border: 1px solid var(--blue-30);
    border-radius: 10px;
    overflow: hidden;
    height: 40px;

    &-error {
      border: 1px solid var(--red-100);
    }

    &__button {
      flex: 1;
      text-align: center;
      padding: 10px 16px;
      cursor: pointer;
      color: var(--grey-70);
      background-color: #e7f1fc;
      transition: background-color 0.2s, color 0.2s;
      white-space: nowrap;
      @include font-body-3;

      &:not(:last-child) {
        border-right: 1px solid #c0c0c0;
      }

      &-error {
        &:not(:last-child) {
          border-right: 1px solid var(--red-100);
        }
      }

      &.selected {
        background-color: var(--blue-50);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        color: #ffffff;
      }
    }
  }

  small {
    color: var(--grey-60);
    margin-top: 4px;
    height: 10px;
    @include font-description-1;

    >span {
      &.error-message {
        color: var(--red-100)
      }
    }
  }
}

.tooltip{
    background-color: var(--grey-70);

  ul{
    padding-left: 16px;
    @include font-description-1;
  }

  &__title{
    @include font-description-1;
  }
}

.v-tooltip__content{
      background-color: var(--grey-70);
      max-width: 221px;
      padding: 6px 8px;

      &:before{
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background-color: var(--grey-70);
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%) rotate(130deg);
        z-index: -1;

        @media (max-width: $mobile-width){
          left: 46%;
        }
      }
}
</style>