<script>
import MediaArticleCard from "./MediaArticleCard";
import {mediaArticles} from "../../jsons/media-articles";

export default {
  name: 'MediaArticleList',
  components: {
    MediaArticleCard
  },
  
  data() {
    return {
      articles: mediaArticles,
      windowWidth: window.innerWidth,
      displayedArticleCount: 4,
    }
  },
  
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  },
  
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 1201 && this.windowWidth > 743) {
        this.displayedArticleCount = 2
      } else if (this.windowWidth < 744) {
        this.displayedArticleCount = 1
      } else {
        this.displayedArticleCount = 4
      }
    }
  }
}
</script>

<template>
  <div class="media">
    <div class="media__list g-row" v-if="articles.length > 0">
      <div
        v-for="news in articles.slice(0, displayedArticleCount)"
        :key="news.id"
        class="g-col-lg-3 g-col-md-3 g-col-sm-4 g-col-xs-2 media__list__card"
      >
        <MediaArticleCard :media="news"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.media {
  width: 100%;
  
  &__list {
    width: 100%;
    margin-top: 20px;

    &__card {
      margin-bottom: $size-40;
    }
  }
}
</style>
