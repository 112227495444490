<script>
import Video from "./VideoFullWidth.vue";
import { reachYMGoal } from "@/utils/metrika";
export default {
  components: {
    Video
  },
  
  data () {
    return {
      videos: [
        {
          id: 8,
          title: "TransRussia 2024",
          subtitle: "Участие Vedexx на выставке транспортно-логистических услуг",
          videoUrl: "exhibition2024.mp4",
          coverUrl: "video_cover-exhibition2024.png"
        },
        {
          id: 9,
          title: "12 причин зарегистрироваться на Vedexx",
          subtitle: "",
          videoUrl: "reasons_to_register.mp4",
          coverUrl: "video_cover_12reasons.png"
        },
        {
          id: 10,
          title: "Для кого предназначен Vedexx и какие проблемы он может решить?",
          subtitle: "",
          videoUrl: "who_is_vedexx_for.mp4",
          coverUrl: "video_cover_roman.png"
        },
        {
          id:  7,
          title: "",
          subtitle: "",
          videoUrl: "webinar.mp4",
          poster: "slide_webinar.png",
          coverUrl: "slide_webinar.png",
          coverUrlMobile: "slide_webinar_mobile.webp",
          titleMobile: "Вебинар от экспертов",
          subtitleMobile: "Логистика 2024 — что изменится в организации ВЭД",
        },
        {
          id:  6,
          title: "Vedexx White Night",
          subtitle: "Презентация системы Vedexx",
          videoUrl: "White_night.mp4",
          coverUrl: "video_cover-wn.webp"
        },
        {
          id:  1,
          title: "IT-логистика с Vedexx",
          subtitle: "Освободи своё время, логистику оставь на Vedexx",
          videoUrl: "Vedexx_video1.mp4",
          coverUrl: "video_cover1.webp"
        },
        {
          id:  2,
          title: "TransRussia 2023",
          subtitle: "Участие Vedexx на выставке транспортно-логистических услуг",
          videoUrl: "exhibition.mp4",
          coverUrl: "video_cover-exhibition.webp"
        },
        {
          id:  3,
          title: "IT-логистика с Vedexx",
          subtitle: "Доминируй на рынке перевозок и ВЭД",
          videoUrl: "Vedexx_video2.mp4",
          coverUrl: "video_cover2.webp"
        },
        {
          id:  4,
          title: "Конференция ТПП",
          subtitle: "Презентация Vedexx на Санкт-Петербургской Торгово-Промышленной палате",
          videoUrl: "TPP.mp4",
          coverUrl: "video_cover-tpp.png"
        },
        {
          id:  5,
          title: "IT-логистика с Vedexx",
          subtitle: "Что может Vedexx за 5 секунд",
          videoUrl: "Vedexx_video3.mp4",
          coverUrlMobile: "Vedexx_video3_mobile.png",
          coverUrl: "video_cover3.webp"
        },
      ]
    }
  },
  
  methods: {
    changeCycle() {
      this.cycle = !this.cycle
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },
  }
}
</script>
<template>
  <div class="video-slider">
    <v-carousel
      class="video-slider__carousel"
      hide-delimiter-background
      show-arrows-on-hover
      delimiter-icon=""
      @change="addMetrics('VideoSwitch')"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="white"
          rounded
          icon
        >
          <img class="video-slider__arrow" src="../../../assets/img/icons/mdi/mdi-chevron-left.svg" alt="mdi-chevron-left">
        </v-btn>
      </template>
      
      <template v-slot:next="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="white"
          rounded
          icon
        >
          <img class="video-slider__arrow" src="../../../assets/img/icons/mdi/mdi-chevron-right.svg" alt="mdi-chevron-right">
        </v-btn>
      </template>
      
      <v-carousel-item
        v-for="(video, i) in videos"
        :key="i"
        class="info-slider__carousel__item"
      >
        <Video
          class="video-slider__carousel__item-video"
          :video="video"
        />
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<style scoped lang="scss">
.video-slider {
  width: 100%;
  cursor: pointer;
  max-height: 65%;
  
  &__carousel {
    
    &__item {
      
      &-video {
        height: 90%;
      }
    }
  }
  &__arrow{
    width: 24px;
    height: 24px;
  }
}

.v-window {
  &-x-transition,
  &-x-reverse-transition,
  &-y-transition,
  &-y-reverse-transition {
    &-enter-active,
    &-leave-active {
      transition: 1.4s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
  }
}
</style>
