<script>
import RateInfoCircles from ".//RateInfoCircles.vue";
import RateCardHeader from "./RateCardHeader.vue";
import RateCardDeliveryDates from "./RateCardDeliveryDates.vue";
import RateCardDeliveryPrice from "./RateCardDeliveryPrice.vue";
import { bus } from "@/router/bus";

export default {
  name: "RateCard",

  components: {
    RateInfoCircles,
    RateCardHeader,
    RateCardDeliveryDates,
    RateCardDeliveryPrice
  },

  props: {
    rate: { type: Object, required: true },
    deliveryPlace: String,
    type: {
      type: String,
      required: false,
      default: () => "usual",
      validator: (value) => ["usual", "recommend"].includes(value),
    },

    isAuth: Boolean
  },

  data() {
    return {
      isBlockZero: false,
    };
  },

  methods: {
    checkBlockVisibility(block) {
      this.isBlockZero = block.offsetWidth === 0 && block.offsetHeight === 0;
    },

    showLoginForm() {
      bus.$emit("showloginform", 'login');
    },

    showRequestForm(item){
      if (this.isAuth) {
        bus.$emit("show_send_request", { rate: item});
      } else {
        this.showLoginForm()
      }
    },

    openRateView(v_key, v_on_date) {
      if (this.isAuth) {
        var queryParams = {
          key: v_key,
          on_date: v_on_date,
          cid: this.$_getsetting("client_id")
        }
        if (this.deliveryPlace != null) {
          queryParams.delivery = this.deliveryPlace
        }
        let routeData = this.$router.resolve({
          path: "/RatePage",
          query: queryParams
        });
        window.open(routeData.href, '_blank');
      } else {
        this.showLoginForm()
      }
    },
  },
}
</script>

<template>
  <div class="new-card">
    
    <RateCardHeader
      :type="type"
      :rate="rate"
      @openRatePage="openRateView(rate.key_orig, rate.on_date)"
    />
    <div class="new-card__delivery-info" :class="{ 'not-auth' : !isAuth }">
      <RateCardDeliveryDates 
        class="new-card__delivery-info__content"
        :detailsLength="rate.detail.length" 
        :isAuth="isAuth" 
        :rateAllTIme="rate.all_time" 
        :seaStartDates="rate.sea_start_dates"
      />
      <RateCardDeliveryPrice 
        class="new-card__delivery-info__content"
        :isAuth="isAuth" 
        :totalPrice="rate.total" 
        :detailInfo="rate.detail"
      />
    </div>

    <div class="new-card__bottom">
      <RateInfoCircles
        @block-mounted="checkBlockVisibility"
        :class="isBlockZero ? '' : 'new-card__transit-info-circle'"
        :rate="rate"/>
      <div class="new-card__bottom-btns">
        <button
          class="new-card__bottom-btn new-card__bottom-btn--blue"
          @click="openRateView(rate.key_orig, rate.on_date)"
          v-ripple>
          Подробнее
        </button>
        <button
          class="new-card__bottom-btn new-card__bottom-btn--orange"
          @click="showRequestForm(rate)"
          v-ripple>
          Оформить
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.new-card {
  padding: 24px;
  border-radius: 12px;
  border: 2px solid var(--grey-30);

  @media (max-width: $mobile-width) {
    padding: 24px 16px;
   // border: unset;
  //  border-radius: unset;
    border-top: 2px solid var(--grey-30);
    border-bottom: 2px solid var(--grey-30);
  }

  &__delivery-info {
    display: grid;
    align-items: center;
    gap: 16px;
    // grid-template-columns: repeat(2, minmax(min-content, max-content));
    grid-template-columns: repeat(2, minmax(calc(50% - 12px), 50%));
    margin: 24px 0;

    &__content {
      position: relative;
      // max-width: calc(100% - 12px);
      width: 100%;

      @media (max-width: $tablet-width) {
        width: 100%;
      }
    }
    
    &.not-auth {
      justify-content: space-between;
      align-content: flex-end;
    }

    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(1, minmax(100%, 100%));
    }

    @media (max-width: $mobile-width) {
      margin: 16px 0;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile-width) {
      flex-direction: column;
      gap: 16px;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &-btns {
      display: flex;
      gap: 0 8px;

      @media (max-width: $tablet-width) {
        margin-left: auto;
      }

      @media (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        width: 100%;
      }
    }

    &-btn {
      padding: 12px 24px;
      border-radius: 16px;
      border: 1px solid;
      transition: .2s;
      @include font-description-semibold-0;

      &--blue {
        border-color: var(--grey-50);
        color: var(--blue-50);
      }

      &--orange {
        border-color: var(--orange-70);
        background-color: var(--orange-70);
        color: #FFF;
      }

      @media (max-width: 425px) {
        padding: 12px 39px;
      }
    }
  }

  &:has(.new-card__body){
    .new-card__body{
      position: relative;
    }
  }
}

.new-card__transit-info-circle{
      @media (min-width: $mobile-width) and (max-width: $tablet-width) {
        // position: absolute;
        // bottom: 66px;
        // top: unset;
      }

      @media (max-width: $mobile-width) {
        display: none;
      }
    }
</style>