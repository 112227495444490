<script>
export default {
  name: "PublicHolidaysBtn.vue",
  props: {
    holidays: Array,
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    getABTestParams() {
      return +this.$_getsetting("ab_test")
    }
  },

  methods: {
    showList() {
      console.log(123)
      return this.show = true
    },

    hideList() {
      return this.show = false
    },

    formatDate(date) {
      let formattedDate  = new Date(date).toLocaleString("ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      return formattedDate[0] == 0 ? formattedDate.slice(1) : formattedDate
    },
  },
};
</script>
<template>
  <div class="holidays">
    <p class="holidays__title" :class="getABTestParams === 1 ? 'holidays__title-grey' : 'holidays__title-white'" @click="showList">
      Праздничные дни
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 16.4355V16.4444M12 7.55556V13.7778M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
          stroke="#E40101"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </p>
    <div v-if="show" class="holidays__list">
      <div>
        <p class="holidays__list-title">Праздничные дни:</p>
        <div class="holidays__list__container">
          <div
            v-for="(holiday, index) of holidays"
            :key="index"
            class="holidays__list-item"
          >
            <p class="holidays__item-dates">
              {{ formatDate(holiday.date_from) }} -
              {{ formatDate(holiday.date_to) }}
            </p>
            <span class="holidays__item-name">{{ holiday.holiday_name }}</span>
          </div>
        </div>
      </div>
      <img
          class="holidays__list-close"
          src="../../assets/img/icons/cancel.svg"
          alt="close"
          @click="hideList"
        />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.holidays {
  position: relative;
  width: fit-content;

  @media (max-width: $mobile-width) {
    position: initial;
    width: 100%;
  }

  &__title {
    display: flex;
    gap: 0 5px;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    @include font-description-1;

    &-white {
      color: #fff;
    }

    &-grey {
      color: var(--grey-60);
    }

    & > svg {
      path {
        stroke: var(--grey-70);
      }
    }
  }

  &__list {
    position: absolute;
    top: 30px;
    z-index: 100;
    align-self: start;
    align-items: flex-start;
    border-radius: 8px;
    border: 2px solid var(--grey-50);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 18px 16px 16px 24px;
    right: 0;
    display: flex;
    column-gap: 8px;
    gap: 8px;

    @media (max-width: $mobile-width) {
      right: unset;
      width: 100%;
      height: fit-content;
      justify-content: space-between;
    }

    &-close {
      position: relative;
      padding: 0 2px;
      bottom: 4px;
      cursor: pointer;
    }

    &-item {
      @include font-description-semibold-1;
      display: flex;
      width: max-content;
      gap: 0 5px;
      margin: 12px 0;

      @media (max-width: $mobile-width) {
        flex-direction: column;
        gap: 12px 0;
      }
    }

    &-title {
      @include font-description-semibold-0;
    }
  }

  &__item {
    &-name {
      font-weight: 400;
    }
  }
}
</style>