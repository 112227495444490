<script>
import AppInputSlider from "@/components/ui/AppInputSlider.vue";
import AppDropDown from "@/components/ui/AppDropDown.vue";
import AppCheckbox from "@/components/ui/AppCheckbox.vue";
import AppButton from "@/components/ui/AppButton.vue";

import { bus } from "@/router/bus";

export default {
  name: "RateFilter.vue",

  components: {
    AppInputSlider,
    AppDropDown,
    AppCheckbox,
    AppButton
  },

  props: {
    baseparams:  Object,
    isAuth:  Boolean,
  },

  data: vm => ({
    ex1: {
      label: 'Стоимость',
      val: vm.baseparams.maxprice,
      color: '#a7c2d3',
      min: vm.baseparams.minprice,
      max: vm.baseparams.maxprice
    },

    ports_ids: {
      is_northwest_region: [],
      is_south_region: [],
      is_fareast_region: [],
    },

    is_direct: false,
    is_not_sea: false,
    is_lack_of_empty_equipment_date: false,
    selected_ports: [],
    ports: [],
    selected_stations: [],
    stations: [],
    selected_clients: [],
    clients: [],
    selected_contractors: [],
    contractors: [],
    price_val: 0,
    tz_val: 0,
    coc_soc: 0,
    is_soc: [0, 1],
    is_unverified: [0, 1],
    is_schedule: [0, 1],
    is_ete: [0, 1],
    is_not_direct_route: [0, 1, 2],
    is_not_vtt: [0, 1],
    is_kfk: [0, 1],
    is_agent: [0, 1],
    is_ch_unverified: false,

    is_kfk_value: false,
    is_unverified_value: false,
    is_not_vtt_value: false,
    is_schedule_value: false,
    equipment_value: [],
    router_value: [],

    routerOptions: [
      { label: "Прямые поезда", name: "filterTrain" },
      { label: "Через Санкт-Петербург", name: "filterSpb" },
      { label: "Через Новороссийск", name: "filterNovoros" },
      { label: "Через Дальний Восток", name: "filterVdk" }
    ],

    equipmentOptions: [
      {label: "Оборудование линии", name: "onlyCoc"},
      {label: "Арендованное оборудование", name: "onlySoc"},
    ],

    filteredLines: [],
    filteredStations: [],
    filteredTerminals: [],

    sort_by: 0,
    sortIcon: require('../../assets/img/icons/sort.svg'),
  }),

  watch: {
    baseparams(val) {
      this.ex1 = {
        label: 'Стоимость',
        val: ((this.price_val == val.maxprice || this.price_val == 0) ? val.maxprice : this.price_val),
        color: '#a7c2d3',
        min: val.minprice,
        max: val.maxprice
      }

      if (Object.keys(val).length == 0) {
        this.resetselected()
        this.price_val = 0
        this.resetFastFilters()
      } else {
        this.ports = val.ports
        this.clients = val.clients
        this.stations = val.stations
        this.contractors = val.contractors
        if (val.reset === true) {
          this.filteredLines = val.contractors
          this.filteredStations = val.stations
          this.filteredTerminals = val.ports
        }
      }
    },
  },

  computed: {
    clientId() {
      return Number(this.$_getsetting('client_id'))
    },
  },

  mounted() {
    bus.$on("chooseFastFilter", data => {
      this.selectFastFilter(data)
    })

    bus.$on("resetFilter", () => {
      this.resetselected()
      this.resetFastFilters()
      this.estimation()
    })

    this.ex1.val = this.baseparams.maxprice
    this.ports = this.baseparams.ports
    this.clients = this.baseparams.clients
    this.stations = this.baseparams.stations
    this.contractors = this.baseparams.contractors

    if (this.baseparams.contractors) this.filteredLines = this.baseparams.contractors;
    if (this.baseparams.stations) this.filteredStations = this.baseparams.stations;
    if (this.baseparams.ports) this.filteredTerminals = this.baseparams.ports;

    // this.filteredStations = this.baseparams.stations
    // this.filteredTerminals = this.baseparams.ports
  },

  methods: {
    resetselected() {
      this.selected_contractors = []
      this.selected_stations = []
      this.selected_ports = []
      this.selected_clients = []
      this.is_not_sea = false
      this.is_not_direct_route = [0, 1, 2]
      this.is_ete = [0, 1],
      this.is_not_vtt = [0, 1],
      this.is_kfk = [0, 1],
      this.is_soc = [0, 1]
      this.is_unverified = [0, 1],
      this.is_schedule = [0, 1],
      this.is_agent = [0, 1],
      this.ports_ids = {
        is_northwest_region: [],
        is_south_region: [],
        is_fareast_region: []
      }
      this.sort_by = 0
      this.ex1.val = this.ex1.max

      this.filteredLines = this.contractors
      this.filteredStations = this.stations
      this.filteredTerminals = this.ports
    },

    selectFastFilter(data) {
      if (data == 'deep_sea') {
        this.chooseFastFilter('filterNovoros')
        this.chooseFastFilter('filterSpb')
        this.router_value = ['filterSpb', 'filterNovoros']

      } else if (data == 'short_sea') {
        this.chooseFastFilter('filterVdk')
        this.router_value = ['filterVdk']
      } else if (data == 'is_not_sea') {
        this.chooseFastFilter('filterTrain')
        this.router_value = ['filterTrain']
      }
      this.estimation()
      this.setFilter(true)
    },

    resetFastFilters() {
      this.is_kfk_value = false
      this.is_unverified_value = false
      this.is_not_vtt_value = false
      this.is_schedule_value = false
      this.equipment_value = []
      this.router_value = []
    },

    chosenFilter(value) {
      if (!this.selected_ports.length && !this.selected_clients.length && !this.selected_stations.length && !this.selected_contractors.length) {
        this.$emit('selectFilter', null)
      } else {
        this.$emit('selectFilter', value)
      }
    },

    estimation() {
      this.price_val = this.ex1.val
      bus.$emit("filterratelist", {
        price_limit: this.price_val,
        is_direct: this.getDirect(),
        is_not_sea: this.getNotSea(),
        is_ete: this.is_ete,
        is_not_vtt: this.is_not_vtt,
        is_kfk: this.is_kfk,
        selected_port: this.selected_ports,
        selected_client: this.selected_clients,
        selected_station: this.selected_stations,
        selected_contractor: this.selected_contractors,
        is_soc: this.is_soc,
        is_not_direct_route: this.is_not_direct_route,
        is_agent: this.is_agent,
        is_lack_of_empty_equipment_date: this.getLackOfEmptyEquipment(),
        is_unverified: this.is_unverified,
        is_schedule: this.is_schedule
      });
    },

    setFilter(value) {
      this.$emit('setFilter', value)
    },

    chooseFastFilter(name) {
      switch (name) {
        case 'filterSpb':
          this.addFastPortFilter('is_northwest_region');
          break
        case 'filterNovoros':
          this.addFastPortFilter('is_south_region');
          break
        case 'filterVdk':
          this.addFastPortFilter('is_fareast_region');
          break
        case 'filterServices':
          this.is_ete = [1];
          break
        case 'filterDirectRoute':
          this.is_not_direct_route = [0, 2];
          break
        case 'filterTrain':
          this.is_not_sea = true;
          break
        case 'onlyCoc':
          this.is_soc = [0];
          break
        case 'onlySoc':
          this.is_soc = [1];
          break
        case 'onlyVerified':
          this.is_unverified = [0];
          break
        case 'onlyShipSchedule':
          this.is_schedule = [1];
          break
        case 'onlyAgentOffer':
          this.is_agent = [1];
          break
        case 'onlyVTT':
          this.is_not_vtt = [0];
          break
        case 'isKFK':
          this.is_kfk = [1];
          break
      }
    },

    clearFastFilter(name) {
      switch (name) {
        case 'filterSpb':
          this.clearFastPortFilter('is_northwest_region');
          break
        case 'filterNovoros':
          this.clearFastPortFilter('is_south_region');
          break
        case 'filterVdk':
          this.clearFastPortFilter('is_fareast_region');
          break
        case 'filterServices':
          this.is_ete = [0, 1];
          break
        case 'filterDirectRoute':
          this.is_not_direct_route = [0, 1, 2];
          break
        case 'filterTrain':
          this.is_not_sea = false;
          break
        case 'onlyCoc':
          this.is_soc = [0, 1];
          break
        case 'onlyVerified':
          this.is_unverified = [0, 1];
          break
        case 'onlyShipSchedule':
          this.is_schedule = [0, 1];
          break
        case 'onlyAgentOffer':
          this.is_agent = [0, 1];
          break
        case 'onlyVTT':
          this.is_not_vtt = [0, 1];
          break
        case 'isKFK':
          this.is_kfk = [0, 1];
          break
      }
    },

    onlyVerified() {
      (this.is_ch_unverified) ?
        this.chooseFastFilter({
          title: 'Только сервисы со статистикой',
          name: 'onlyVerified',
          on_top: 1
        }) 
      : this.resetselected(), this.price_val = 0
    },

    addFastPortFilter(region) {
      if (this.selected_ports[0] === null) {
        this.selected_ports = []
      }
      if (this.ports_ids[`${region}`].length == 0) {
        let result = this.ports.filter(elem => elem[region] == 1)
        for (let i = 0; i < result.length; i++) {
          this.selected_ports.push(result[i].id)
          this.ports_ids[`${region}`].push(result[i].id)
        }
      } else {
        for (let i = 0; i < this.ports_ids[`${region}`].length; i++) {
          this.selected_ports.push(this.ports_ids[`${region}`][i])
        }
      }
      if (this.selected_ports.length === 0) {
        this.selected_ports = [null]
      } else {
        this.filterStations();
        this.filterLines();
      }
    },

    filterRoute() {
      this.routerOptions = [ {label: "Прямые поезда", name: "filterTrain"}];
      if (this.selected_ports.length > 0 || this.selected_contractors.length > 0 || this.selected_stations.length > 0) {
        const regionsMap = {
          is_northwest_region: {label: "Через Санкт-Петербург", name: "filterSpb"},
          is_south_region: {label: "Через Новороссийск", name: "filterNovoros"},
          is_fareast_region: {label: "Через Дальний Восток", name: "filterVdk"}
        }

        const addedRegions = new Set()

        if (this.selected_ports.length > 0) {
          this.selected_ports.forEach(port => {
            const selected_port = this.ports.find(item => item.id === port)
            for (let region in regionsMap) {
              if (selected_port[region] === 1 && !addedRegions.has(region)) {
                this.routerOptions.push(regionsMap[region])
                addedRegions.add(region)
              }
            }
          });
        }

        if (this.selected_contractors.length > 0) {
          this.selected_contractors.forEach(contractor => {
            const selected_contractor = this.contractors.find(item => item.id === contractor)
            for (let region in regionsMap) {
              if (selected_contractor[region] === 1 && !addedRegions.has(region)) {
                this.routerOptions.push(regionsMap[region])
                addedRegions.add(region)
              }
            }
          });
        }

        if (this.selected_stations.length > 0) {
          this.selected_stations.forEach(station => {
            const selected_station = this.stations.find(item => item.id === station)
            for (let region in regionsMap) {
              if (selected_station[region] === 1 && !addedRegions.has(region)) {
                this.routerOptions.push(regionsMap[region])
                addedRegions.add(region)
              }
            }
          });
        }
      } else {
        this.routerOptions.push(
          { label: "Через Санкт-Петербург", name: "filterSpb" },
          { label: "Через Новороссийск", name: "filterNovoros" },
          { label: "Через Дальний Восток", name: "filterVdk" }
        )
      }
    },

    clearFastPortFilter(region) {
      this.selected_ports = this.selected_ports.filter(elem => !this.ports_ids[`${region}`].includes(elem))
      this.filterStations();
      this.filterLines();
    },

    getLackOfEmptyEquipment() {
      return (this.is_lack_of_empty_equipment_date) ? [0, 0] : [1, 0, 1, 2];
    },

    getDirect() {
      return (this.is_direct) ? [0, 0] : [1, 0, 1];
    },

    getNotSea() {
      return (this.is_not_sea) ? [0, 0] : [1, 0, 1, 2];
    },

    selectRouter() {
      this.selected_ports = []
      for (let i = 0; i < this.routerOptions.length; i++) {
        if (this.router_value.includes(this.routerOptions[i].name)) {
          this.chooseFastFilter(this.routerOptions[i].name)
        } else {
          this.clearFastFilter(this.routerOptions[i].name)
        }
      }
    },

    selectEquipment() {
      if (this.equipment_value.length > 0) {
        this.chooseFastFilter(this.equipment_value)
      } else {
        this.clearFastFilter('onlyCoc')
      }
    },

    filterData() {
      this.filterTerminals()
      this.filterStations()
      this.filterLines()
      this.filterRoute()
    },

    filterTerminals() {
      if (this.selected_contractors.length > 0 || this.selected_stations.length > 0) {
        const terminalIds = new Set();

        if (this.selected_contractors.length > 0) {
          this.selected_contractors.forEach(lineId => {
            const line = this.contractors.find(l => l.id === lineId);
            line.terminal_ids.forEach(id => terminalIds.add(id));
          });
        }

        if (this.selected_stations.length > 0) {
          this.selected_stations.forEach(stationId => {
            const station = this.stations.find(s => s.id === stationId);
            station.terminal_ids.forEach(id => terminalIds.add(id));
          });
        }

        this.filteredTerminals = this.ports.filter(terminal => terminalIds.has(terminal.id));
      } else {
        this.filteredTerminals = this.ports;
      }

    },

    filterLines() {
      if (this.selected_ports.length > 0 || this.selected_stations.length > 0) {
        const lineIds = new Set();

        if (this.selected_ports.length > 0) {
          this.selected_ports.forEach(terminalId => {
            const terminal = this.ports.find(t => t.id === terminalId);
            terminal.line_ids.forEach(id => lineIds.add(id));
          });
        }

        if (this.selected_stations.length > 0) {
          this.selected_stations.forEach(stationId => {
            const station = this.stations.find(s => s.id === stationId);
            station.line_ids.forEach(id => lineIds.add(id));
          });
        }

        this.filteredLines = this.contractors.filter(line => lineIds.has(line.id));
      } else {
        this.filteredLines = this.contractors;
      }
    },

    // setNewThreeBestRateParams() {
    //   bus.$emit("set_newsletter_rate");
    // },

    filterStations() {
      if (this.selected_ports.length > 0 || this.selected_contractors.length > 0) {
        const stationIds = new Set();

        if (this.selected_ports.length > 0) {
          this.selected_ports.forEach(terminalId => {
            const terminal = this.ports.find(t => t.id === terminalId);
            terminal.station_ids.forEach(id => stationIds.add(id));
          });
        }

        if (this.selected_contractors.length > 0) {
          this.selected_contractors.forEach(lineId => {
            const line = this.contractors.find(l => l.id === lineId);
            line.station_ids.forEach(id => stationIds.add(id));
          });
        }

        this.filteredStations = this.stations.filter(station => stationIds.has(station.id));
      } else {
        this.filteredStations = this.stations;
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="rate-filter">
      <AppInputSlider
        label="Стоимость"
        class="rate-filter__item"
        v-model="ex1.val"
        :max-value="ex1.max"
        :min-value="ex1.min"
      />

      <div class="rate-filter__item">
        <AppCheckbox
          v-model="is_kfk_value"
          label="Есть оформление КФК"
          class="rate-filter__item-checkbox"
          @input="is_kfk_value  ? chooseFastFilter('isKFK') : clearFastFilter('isKFK')"
        />

        <AppCheckbox
          v-model="is_not_vtt_value"
          label="Есть ВТТ"
          class="rate-filter__item-checkbox"
          @input="is_not_vtt_value  ? chooseFastFilter('onlyVTT') : clearFastFilter('onlyVTT')"
        />
      </div>

      <div class="rate-filter__item">
        <AppCheckbox
          v-model="is_unverified_value"
          label="Только со статистикой"
          class="rate-filter__item-checkbox"
          @input="is_unverified_value  ? chooseFastFilter('onlyVerified') : clearFastFilter('onlyVerified')"
        />

        <AppCheckbox
          v-model="is_schedule_value"
          label="Только с расписанием"
          class="rate-filter__item-checkbox"
          @input="is_schedule_value  ? chooseFastFilter('onlyShipSchedule') : clearFastFilter('onlyShipSchedule')"
        />
      </div>

      <AppDropDown
        v-if="clientId === -1"
        label="Клиент NAK"
        v-model="selected_clients"
        :options="clients"
        item-text="name"
        class="rate-filter__item"
        item-value="id"
        placeholder="Выберите клиента"
        multiple
        autocomplete
        :limit-display="2"
      />

      <AppDropDown
        v-if="isAuth"
        label="Линия"
        v-model="selected_contractors"
        :options="filteredLines"
        item-text="name"
        class="rate-filter__item"
        item-value="id"
        placeholder="Выберите линию"
        multiple
        autocomplete
        :limit-display="2"
        @input="[selected_contractors.length > 0 ? chosenFilter('contractors') : chosenFilter(null)], filterData()"
      />

      <AppDropDown
        v-if="isAuth"
        label="Порт/Терминал прибытия"
        v-model="selected_ports"
        :options="filteredTerminals"
        item-text="name"
        class="rate-filter__item"
        item-value="id"
        placeholder="Выберите порт"
        multiple
        :limit-display="2"
        @input="[selected_ports.length > 0 ? chosenFilter('ports') : chosenFilter(null)], filterData()"
      />

      <AppDropDown
        label="Станция прибытия/Город"
        v-model="selected_stations"
        :options="filteredStations"
        item-text="name"
        class="rate-filter__item"
        item-value="id"
        placeholder="Выберите станцию"
        multiple
        :limit-display="2"
        @input="[selected_stations.length > 0 ? chosenFilter('stations') : chosenFilter(null)], filterData()"
      />

      <AppDropDown
        label="Маршрут доставки"
        v-model="router_value"
        :options="routerOptions"
        item-text="label"
        class="rate-filter__item"
        item-value="name"
        placeholder="Выберите маршрут"
        multiple
        :limit-display="2"
        @input="selectRouter"
      />

      <AppDropDown
        label="Оборудование"
        v-model="equipment_value"
        :options="equipmentOptions"
        item-text="label"
        class="rate-filter__item rate-filter__item--equipment"
        item-value="name"
        placeholder="Выберите оборудование"
        clearable
        @input="selectEquipment"
      />

      <!-- <div class="rate-filter__sort">
        <img class="rate-filter__sort-icon" alt="sort" :src="sortIcon"/>

        <div @click="setsort(1)">
          <AppTag
            label="Сортировать по скорости"
            class="rate-filter__sort-tag"
            :class="{ checked: sort_by === 1 }"
            :color="sort_by === 1 ? 'blue' : 'gray'"
          />
        </div>

        <div @click="setsort(0)">
          <AppTag
            label="Сортировать по цене"
            class="rate-filter__sort-tag"
            :class="{ checked: sort_by === 0 }"
            :color="sort_by === 0 ? 'blue' : 'gray'"
            @click="setsort(0)"
          />
        </div>

        <div @click="setsort(3)">
          <AppTag
            label="Ближайший выход"
            class="rate-filter__sort-tag"
            :class="{ checked: sort_by === 3 }"
            :color="sort_by === 3 ? 'blue' : 'gray'"
            @click="setsort(3)"
          />
        </div>
      </div> -->

      <div class="rate-filter__footer">
        <!-- <AppButton
          color="secondary-blue"
          class="rate-filter__footer-btn-subscribe"
          label="Подписаться на направление"
          @click="setNewThreeBestRateParams"
        /> -->
        <AppButton
            color="blue"
            size="medium"
            class="rate-filter__footer-btn-save"
            label="Применить"
            @click="estimation(), setFilter(true)"
          />
          <AppButton
            color="grey"
            class="rate-filter__footer-btn-reset"
            label="Сбросить"
            @click="resetFastFilters(), resetselected(), estimation(), setFilter(false)"
          />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rate-filter {
  border-radius: 0 0 16px 16px;
  background: var(--grey-5);
  backdrop-filter: blur(28px);
  padding: 24px 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  position: relative;
  align-content: center;
  align-items: center;

  @media (max-width: $mobile-width) {
    padding: 16px 14px;
    gap: 16px;
  }

  &__item {
    width: calc(33% - 8px);
    text-align: left;
    position: relative;

    &--equipment{
      flex: 0 0 33%;
    }
    
    @media (max-width: $tablet-width) {
      flex: calc(50% - 28px);
    }

    @media (max-width: $mobile-width) {
      flex: 100%;
    }

    // &:nth-child(7), &:nth-child(8) {
    //   width: calc(50% - 14px);

    //   @media (max-width: $tablet-width) {
    //     flex: calc(50% - 28px);
    //   }

    //   @media (max-width: $mobile-width) {
    //     flex: 100%;
    //   }
    // }

    &-checkbox {
      margin: 15px;

      @media (max-width: $mobile-width) {
        flex: 40% !important;
        margin: 15px 0;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    > div {
      display: flex;
    }

    // &-btn-subscribe {
    //   width: 300px;
    // }

    &-btn-reset {
      width: 121px;
    }

    &-btn-save {
      width: 135px;
      margin-right: 16px;
    }
  }
}

</style>