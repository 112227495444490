import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-page"},[_vm._m(0),_c('div',{ref:"searchRates",staticClass:"main-page__tabs-container",attrs:{"id":"searchRates"}},[(_vm.isAuth && _vm.windowWidth > 743)?_c(VTabs,{ref:"searchRates",staticClass:"main-page__tabs",class:_vm.isAuth === true ? '' : 'main-page__tabs-unauth-width',attrs:{"id":"searchRates","color":"none","centered":"","active-class":"main-page__tab-active"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{staticStyle:{"display":"none"}}),(_vm.windowWidth > 743)?_c(VTab,{staticClass:"main-page__tab"},[_vm._v("Один маршрут")]):_c(VTab,{staticClass:"main-page__tab"},[_vm._v("Маршрут")]),(_vm.isAuth === true)?_c(VTab,{staticClass:"main-page__tab main-page__tab-centered"},[_vm._v("Несколько")]):_vm._e()],1):_vm._e()],1),_c(VTabsItems,{staticClass:"main-page__find-panel",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('FindRatePanel',{ref:"findRates",staticClass:"main-page__find-rate",class:{ 'main-page__find-rate-unauth': !_vm.isAuth || _vm.windowWidth < 743 }})],1),(_vm.isAuth === true)?_c(VTabItem,[_c('FindComplexRatePanel',{staticClass:"main-page__find-complex",attrs:{"searchParams":_vm.searchParams}})],1):_vm._e()],1),(_vm.searchOneRateClicked)?_c('RateList',{staticClass:"main-page__rate-list g-col-lg-12",class:_vm.isAuth ? '' : 'main-page__rate-list-unauth',attrs:{"searchParams":_vm.searchParams}}):_vm._e(),(_vm.searchComplexRateClicked)?_c('RateComplexList',{staticClass:"main-page__rate-complex-list",attrs:{"searchParams":_vm.searchParams}}):_vm._e(),(!_vm.searchComplexRateClicked && !_vm.searchOneRateClicked)?_c('BestOffers',{staticClass:"main-page__best-offers",attrs:{"country":"ch"}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"main-page__ttl"},[_c('span',[_vm._v("Контейнерная логистика онлайн")]),_c('br'),_c('span',[_vm._v("быстро, просто, достоверно")])])
}]

export { render, staticRenderFns }