<script>
import AppDropDown from "../ui/AppDropDown.vue";
import AppButton from "../ui/AppButton.vue";

import { bus } from "@/router/bus";

export default {
  name: 'FindComplexRatePanel',
  
  components: {
    AppDropDown,
    AppButton
  },

  props: {
    searchParams: { type: Object },
  },
  
  data: () => ({
    lang: 'en',
    
    searchType: 'fn_getratelist_complex',
    historyData: [],
    historyString: [],
    
    selected_line: [],
    places_from: [],
    places_to: [],
    linelist: [],

    inputRules: [
      v => (v && v.length > 0) || 'Не выбрано',
    ],

    place_from: [],
    place_to: [],
    selectedLines: []
  }),
  
  mounted(){
    this.getReferenceList('lgst_place_from_wg')
    this.getReferenceList('lgst_place_to_wg')
    this.getReferenceList('LgstLineList')
    this.getSearchParams()
    // this.getSearchParamsHistory()
  },
  computed: {
    filteredLinelist() {
      if(this.selected_line.length > 1){
        if (this.selected_line.includes('0')) {
          return this.linelist.slice(1)
        }
      }
      return this.linelist
    },
  },

  methods: {
    checkLang(str) {      
      if (str.length > 0) {
        if (/[а-я]/i.test(str) == true) {
          this.lang = "ru"
        } else {
          this.lang = "en"
        }
      }
    },

    getSearchParams() {
      if (this.$route.name === 'RateListPage') {
        if (this.searchParams !== null) {
          console.log(this.searchParams)
          this.selected_line = this.searchParams.line_id.split(',')
          this.place_from = this.searchParams.place_from.split(',')
          this.place_to = this.searchParams.place_to.split(',')
        }
      }
    },
    
    getSearchParamsHistory() {
      this.$postapi(this.$address + this.$getters.getSearchParamsHistory.uri, {
        method: this.$getters.getSearchParamsHistory.name,
        search_type: this.searchType,
        token: this.$_getsetting('token')
      }).then(data => {
        if (data.error == 0) {
          this.historyString =JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
          this.historyData = JSON.parse(this.historyString[0].result)
        }
      })
    },
    
    getReferenceList(refName) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: this.$references[refName].name
      }).then(data => {
        if (data.error == 0) {
          switch (refName) {
            case 'lgst_place_from_wg':
              this.places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
              case 'lgst_place_to_wg':
              this.places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'LgstLineList':
              this.linelist = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
              this.sortLines()
              // this.selected_line.push('0')
              break
          }
        } else {
          return []
        }
      });
    },

    sendRequest() {
      this.$refs.place_from.validate()
      this.$refs.place_to.validate()
      this.$refs.selected_line.validate()

      if ((!this.place_from || this.place_from.length == 0 || !this.place_to || this.place_to == 0) || this.selected_line.length === 0) {
      bus.$emit("show_notify", {
        color: "error",
        notifytext: 'Заполните все поля'
      });
      return
    }

    this.openRateListPage()

      // bus.$emit("getcomplexrates", {
      //   on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      //   place_to: this.place_to.join(),
      //   place_from: this.place_from.join(),
      //   line_id: this.selected_line.join(),
      //   unit_code: '6,8',
      //   client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99 ,
      // });
    },

    openRateListPage() {
      var queryParams = {
        on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        place_to: this.place_to.join(),
        place_from: this.place_from.join(),
        line_id: this.selected_line.join(),
        unit_code: '6,8',
        complex_rate: true,
      }
      let routeData = this.$router.resolve({
        path: "/RateListPage",
        query: queryParams
      });
      if (this.$route.name === 'RateListPage') {
        window.location.href = routeData.href;
      } else {
        window.open(routeData.href, '_blank');
      }
    },
    
    search(){
      this.sendRequest()
    },

    removeItem(item) {
      this.selected_line = this.selected_line.filter(i => i !== item.id);
    },

    getResultItems() {
      this.selectedLines = this.linelist.filter(line => this.selected_line.includes(line.id))

      if(this.selected_line.length > 1){
        this.selectedLines = this.selectedLines.filter(line => line.id !== '0')
      }
    },

    sortLines() {
      this.linelist.sort((a, b) => a.name.localeCompare(b.name))
    },
  },
}
</script>

<template>
    <v-form 
      @submit.prevent="search()"
      class="complex-rate-params"
    >
    <div class="row row-1">
      <AppDropDown
        v-model="place_from"
        class="complex-rate-params__autocomplete"
        label="Место отправки"
        ref="place_from"
        :item-text="lang === 'en' ? 'name' : 'alt_name'"
        :rules="inputRules"
        :options="places_from"
        item-description="header"
        item-value="id"
        multiple
        autocomplete
        placeholder="Выбрать"
        @searchInput="checkLang($event)"
        :itemsLimit=20
        description="Не более 20"
     />

     <AppDropDown
        v-model="place_to"
        class="complex-rate-params__autocomplete"
        label="Место доставки"
        ref="place_to"
        :item-text="lang === 'en' ? 'alt_name' : 'name'"
        :rules="inputRules"
        :options="places_to"
        item-value="id"
        item-description="header"
        multiple
        autocomplete
        placeholder="Выбрать"
        @searchInput="checkLang($event)"
        :itemsLimit=5
        description="Не более 5"
     />
    </div>

    <div class="row row-2">
      <AppDropDown
        v-model="selected_line"
        class="complex-rate-params__autocomplete"
        label="Подрядчик"
        ref="selected_line"
        item-text="name"
        :rules="inputRules"
        :options="selectedLines.length < 15 ? filteredLinelist : selectedLines"
        item-value="id"
        item-description="header"
        multiple
        autocomplete
        placeholder="Выбрать"
        :itemsLimit=15
        description="Не более 15"
     />

      <AppButton
        type="submit"
        class="complex-rate-params__btn"
        size="large"
        color="orange"
        label="Найти"
      />
    </div>
  </v-form>
</template>

<style scoped lang="scss">

.complex-rate-params{
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .row {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 16px;
  }

  .row-1 {
    & > :nth-child(1),
    & > :nth-child(2) {
      flex: 1;
      min-width: 0;
    }
  }

  .row-2 {
    & > :nth-child(1) {
      width: calc(100% - 150px);
    }

    & > :nth-child(2) {
      width: 134px; 
    }
  }

  &__autocomplete {
    position: relative;
  }
  
  &__btn {
    width: 134px;
    position: relative;
  }
}
</style>
