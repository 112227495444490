<script>
import { bus } from "@/router/bus";

import PetersburgEmblem from "@/assets/img/icons/emblems/emblem_petersburg.webp"
import MoscowEmblem from "@/assets/img/icons/emblems/emblem_moscow.webp"
import EkbEmblem from "@/assets/img/icons/emblems/emblem_ekb.webp"

export default {
  name: "BestOfferCard",

  props: {
    rate: {
      type: [Array, Object],
    },

    port_from_id: {
      type: Number,
    },
  },

  data() {
    return {
      price: [],
      on_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  computed: {
    currentEmblem() {
      switch (this.rate.place_to_id) {
        case 65:
          return PetersburgEmblem;
        case 74:
          return EkbEmblem;
        default:
          return MoscowEmblem; 
      }
    },
  },

  mounted() {
    this.getRatePrice();
  },

  methods: {
    openRateView() {
      if (this.$_getsetting("is_auth")) {
        let routeData = this.$router.resolve({
          path: "/RatePage",
          query: {
            key: this.price.key_orig,
            on_date: this.on_date,
            cid: this.$_getsetting("client_id"),
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.showLoginForm();
      }
    },

    showLoginForm() {
      bus.$emit("showloginform", "login");
    },

    getRatePrice() {
      this.$postapi(this.$address + this.$getters.getMinRateJson.uri, {
        method: this.$getters.getMinRateJson.name,
        on_date: this.on_date,
        client_id: -99,
        place_to: this.rate.place_to_id,
        place_from: this.port_from_id,
        unit_code: 6,
      }).then((data) => {
        if (data.error == 0) {
          this.price = JSON.parse(data.message);
        }
      });
    },
  },
};
</script>

<template>
  <div class="offer">
    <div class="offer-card" @click="openRateView">
      <div class="offer-card__head">
        <img class="offer-card__logo" :src="currentEmblem" alt="logo" />
        <p class="offer-card__name">{{ rate.place }}</p>
      </div>
      <div class="offer-card__body">
        <p class="offer-card__price">{{ rate.price }} ₽</p>
        <a class="offer-card__more-btn" href="#!">Подробнее</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.offer{
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: unset;
  }
}

.offer-card {
  border-radius: 12px;
  background-color: var(--blue-10);
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px 0;

  max-height: 136px;
  cursor: pointer;

  &__head {
    display: flex;
    gap: 0 12px;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      border: 0.5px solid var(--grey-30);
      width: 100%;
      bottom: -12px;
    }
  }

  &__logo {
    height: 32px;
    width: 41px;
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    @include font-headline-2;
    color: var(--grey-70);
  }

  &__price {
    @include font-headline-2;
    color: var(--grey-70);
  }

  &__more-btn {
    @include font-body-2;
    font-weight: 600;
    text-decoration: none;
    color: var(--grey-70);
  }
}
</style>