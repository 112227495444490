<script>
import BannerPreview from "../banner/BannerPreview.vue";
import { setCookie, getCookie } from "@/utils/cookie";

import {bus} from "@/router/bus";

export default {
  name: "BannerListMobile",
  components: {
    BannerPreview,
  },

  props: {
    load: { type: Number },
  },

  data() {
    return {
      banners: [],
      bannersLimit: 3,
      currentIndex: 0,
      viewedBannerIds: [],
    };
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth
    },

    notViewedBanners() {
      return this.banners.filter((banner) => !this.isViewed(banner.id))
    },

    sortedBanners() {
      return this.banners.slice().sort((a, b) => {
        const aViewed = this.isViewed(a.id)
        const bViewed = this.isViewed(b.id)

        if (aViewed && !bViewed) return 1
        if (!aViewed && bViewed) return -1
        return 0
      });
    },

    currentBanner() {
      return this.sortedBanners[this.currentIndex]
    },

    visibleBanners() {
      return this.sortedBanners.slice(0, 3)
    },
  },

  watch: {
    load(val, oldVal) {
      if (val === 1 && oldVal === 0) {
        this.getTripwires()
        this.showLess()
      }
    },
  },

  inject: ['tripwires'],

  methods: {
    getTripwires(){
      this.banners = this.tripwires.banners
      this.loadViewedBanners()
      this.setNextBannerIndex()
    },

    isViewed(bannerId) {
      return this.viewedBannerIds.includes(bannerId)
    },

    setNextBannerIndex() {
      const maxIndex = this.notViewedBanners.length > 2 ? 2 : this.notViewedBanners.length - 1;
      let nextIndex = parseInt(getCookie('bannerIndex') || 0, 10);

      nextIndex = (nextIndex + 1) % (maxIndex + 1);
      this.currentIndex = nextIndex;
      setCookie('bannerIndex', nextIndex, 1); // 1 день
      if (this.notViewedBanners.length !== 0) {
        this.openFullBanner()
      }
    },

    loadViewedBanners() {
      const viewedBannerIdsString = getCookie('viewedBannerIds');
      if (viewedBannerIdsString) {
        this.viewedBannerIds = JSON.parse(viewedBannerIdsString);
      }
    },

    openFullBanner() {
      bus.$emit('openFullBanner', this.currentBanner)
    },

    showMore() {
      this.bannersLimit > this.banners.length ? null : this.bannersLimit = this.bannersLimit + 2
    },

    showLess(){
      this.windowSize > 1200 ? this.bannersLimit = 3 : this.bannersLimit = 2
    },
  },
};
</script>

<template>
  <div class="banner-list">
    <p class="banner-list__title">Преимущества Vedexx</p>
    <div class="banner-list__banners">
      <BannerPreview
        class="banner-list__banner"
        v-for="banner in banners"
        :key="banner.id"
        :banner="banner"
        :read="isViewed(banner.id)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-list {
  padding: 25px 16px 14px;
  border-bottom: 1px solid var(--grey-10);
  margin-bottom: 10px;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;

    margin-bottom: 16px;
  }

  &__banners {
    display: flex;
    flex-direction: row;
    gap: 0 16px;
    overflow-x: scroll;
  }

  &__banner {
    min-width: 280px;
  }
}
</style>