<script>
import { bus } from "@/router/bus";

export default {
  name: "SlideChineseNewYearCard.vue",
  props: {
    rate: {
      type: [Array, Object],
    },

    port_from_id: {
      type: Number,
    },
  },

  data() {
    return {
      price: [],
      on_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  mounted() {
    this.setUnitCode();
  },

  methods: {
    getRatePrice(unitCode) {
      this.$postapi(this.$address + this.$getters.getMinRateJson.uri, {
        method: this.$getters.getMinRateJson.name,
        on_date: this.on_date,
        client_id: -99,
        place_to: this.rate.place_to_id,
        place_from: this.port_from_id,
        unit_code: unitCode,
      }).then(data => {
        if (data.error == 0) {
          this.price = JSON.parse(data.message);
        }
      });
    },

    openRateView() {
    if (this.$_getsetting("is_auth")) {
      let routeData = this.$router.resolve({
        path: "/RatePage",
        query: {
          key: this.price.key_orig,
          on_date: this.on_date,
          cid: this.$_getsetting("client_id"),
          utm_source: "vedexx",
          utm_medium: "site",
          utm_content: "china_new_year_banner",
        },
      });
      window.open(routeData.href, "_blank");
      } else {
        this.showLoginForm();
      }
    },

    showLoginForm() {
      bus.$emit("showloginform", "login");
    },

    setUnitCode(){
      if (this.rate.place_to_id == 65) {
        this.getRatePrice(8)
      }else{
        this.getRatePrice(6)
      }
    },
  },
};
</script>

<template>
  <div v-if="rate.place_to_id !== 74" class="chinese-newyear-slide__rate">
    <div class="chinese-newyear-slide__rate-item" @click="openRateView">
      <p class="chinese-newyear-slide__rate-title">
        {{ rate.place }}
      </p>
      <div class="chinese-newyear-slide__rate-bottom">
        <p class="chinese-newyear-slide__rate-price">
          {{ parseFloat(price.total).toLocaleString("ru-RU") }} ₽
        </p>
        <svg
          class="chinese-newyear-slide__rate-arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="142"
          height="41"
          viewBox="0 0 142 41"
          fill="none"
        >
          <path
            d="M140 20.5006L113.5 39M140 20.5006L113.5 2M140 20.5006L2 20.5006"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.chinese-newyear-slide {
  &__rate {
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-top: 10px;
    }

    &-item {
      border-radius: 23px;
      background: rgba(30, 35, 41, 0.3);
      backdrop-filter: blur(56px);
      cursor: pointer;

      padding: 17px 24px 17px 47px;
      border: 1px solid transparent;
      transition: 0.2s ease-in-out border;

      @media (max-width: 1450px) {
        padding: 17px 24px 17px 17px;
      }

      &:hover {
        border: 1px solid var(--orange-70);
        .chinese-newyear-slide__rate-arrow {
          transform: translateX(5px);
        }
      }

      @media (max-width: $tablet-width) {
        &:hover {
          .chinese-newyear-slide__rate-arrow {
            transform: unset;
          }
        }
      }
    }

    &-title {
      @include font-body-1;
      font-size: 26px;
      margin-bottom: 12px;

      @media (max-width: 1450px) {
        font-size: 24px;
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        height: 37px;
        width: 100px;
        position: relative;
        transition: 0.2s ease-in-out transform;

        @media (max-width: 1450px) {
          width: 80px;
        }
      }
    }

    &-price {
      @include font-body-1;
      font-weight: 600;
      font-size: 28px;

      border-radius: 6px;
      background: #ff8614;
      padding: 6px 12px;

      @media (max-width: 1450px) {
        font-size: 22px;
      }
    }
  }
}
</style>
