<script>
import { slides } from "@/components/jsons/slides";
import BestOfferCard from "./BestOfferCard.vue";

export default {
  name: "BestOffers",
  components: {
    BestOfferCard,
  },

  props: {
    country: String,
    unitcode: String,
  },

  computed: {
    countryName() {
      const countries = {
        ch: "Китая",
        in: "Индии",
      };
      return countries[this.country];
    },

    logo() {
      return this.logos[this.country];
    },
  },

  data() {
    return {
      ports: slides[1].ports,
      rates: slides[1].rates,
      logos: {
        ch: require("../../../assets/img/icons/china-logo.svg"),
      },
    };
  },
};
</script>

<template>
    <div class="best-offers">
        <div class="best-offers__header">
            <p class="best-offers__title">Лучшее предложение из {{ countryName }}</p>
            <img class="best-offers__img" :src="logo" alt="country" />
        </div>
        <p class="best-offers__delivery">Доставка контейнера {{ unitcode }}</p>
        <div class="best-offers__items">
            <div class="best-offers__item" v-for="(port, idx) in ports" :key="idx">
                <p class="best-offers__port-name">
                    {{ port.name }}
                </p>
                <BestOfferCard v-for="(rate, idx) in rates" :rate='rate' :port_from_id="port.id"  :key="idx" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.best-offers {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  &__header {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &__title {
    @include font-headline-1;
    text-align: center;
    color: var(--grey-70);

    @media (max-width: $tablet-width) {
      @include font-headline-2;
    }
  }

  &__img {
    width: 39px;

    @media (max-width: $tablet-width) {
      width: 24px;
    }
  }

  &__delivery {
    @include font-headline-2;
    text-align: center;
    margin-bottom: 24px;
    color: var(--grey-70);

    @media (max-width: $tablet-width) {
      @include font-headline-3;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    justify-content: space-between;

    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $mobile-width) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__port-name {
    @include font-headline-2;
    color: var(--blue-70);
    margin-bottom: 16px;
    text-align: center;
  }
}
</style>