
<script>

export default {
  name: 'AutoDeliveryCalculatePanel',
  
  props: {
    resetDelivery: {
      type: [String, Object, Number],
      required: true
    },
    
    unit_code: {
      type: Number,
      required: true
    },
    
    city_from_id: {
      type: Number,
      required: true
    },
  },
  
  data: () => ({
    routes: [
      // { id: 59, point_from_id: 59, point_from: "Новороссийск", route_val: 0, price: 0 },
      { id: 72, point_from_id: 72, point_from: "Москва", route_val: 0, price: 0, allow_cities: [72, 65] },
      { id: 73, point_from_id: 73, point_from: "Новосибирск", route_val: 0, price: 0, allow_cities: [73] },
      { id: 74, point_from_id: 74, point_from: "Екатеринбург", route_val: 0, price: 0, allow_cities: [74] },
      { id: 65, point_from_id: 65, point_from: "Санкт-Петербург", route_val: 0, price: 0, allow_cities: [72, 65] },
    ],
    cities_to: [],
    searchCityTo: null,
    deliveryPriceInfo: [],
    deliveryAccess: 1,
    delivery_to: null,
    cityLoadingTo: false,
    price_per_unit: 52,
    
    showLoader: false
  }),
  
  watch: {
    searchCityTo(val) {
      this.cityLoadingTo = true
      this.$postapi(this.$address + this.$getters.getCityList.uri, {
        method: this.$getters.getCityList.name,
        place_name: val
      }).then(data => {
        if (data.error == 0) {
          this.cities_to = JSON.parse(data.message)
          this.cityLoadingTo = false
        }
      });
    },
    
    resetDelivery: function () {
      if (this.resetDelivery == 1) {
        this.delivery_to = null
      }
    },
    deep: true
  },
  
  
  methods: {
    getRoutes() {
      if (this.delivery_to != null) {
        for ( let el of this.routes ) {
          if (el.allow_cities.includes(this.city_from_id)) {
            this.getRoute(el)
          }
        }
      } else {
        for ( let item of this.routes ) {
          item.route_val = 0
          item.price = 0
          this.$emit('setTotalOfRate', { route: item, delivery_to: this.delivery_to })
        }
      }
    },
    
    getRoute(item) {
      this.showLoader = true
      this.$postapi(this.$address + this.$getters.getRoute.uri, {
        method: this.$getters.getRoute.name,
        place_from: item.point_from,
        place_to: this.delivery_to,
      }).then(data => {
        if (data.error == 0) {
          this.routerInfo = JSON.parse(data.message)
          item.route_val = this.routerInfo.kilometers
          if (item.id === 72) {
            this.getAutoServiceInfoMoscow(item)
          } else if (item.id === 65) {
            this.getAutoServiceInfoRegion(item)
          } else {
            this.getApproximatePrice(item)
          }
        }
      });
    },
    
    getAutoServiceInfoMoscow(item) {
      this.deliveryPriceInfo = []
      this.$postapi(this.$address + this.$getters.getAutoServiceInfo.uri, {
        method: this.$getters.getAutoServiceInfo.name,
        distance: item.route_val,
        unit_type: this.unit_code,
        weight: 0,
        is_vtt: 0,
        is_moscow: 1,
      }).then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          let price = 0
          for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
            price = price + this.deliveryPriceInfo[i].cost
          }
          item.price = price
          this.$emit('setTotalOfRate', { route: item, delivery_to: this.delivery_to })
        }
      });
    },
    
    getAutoServiceInfoRegion(item) {
      this.deliveryPriceInfo = []
      this.$postapi(this.$address + this.$getters.getAutoServiceInfoRegion.uri, {
        method: this.$getters.getAutoServiceInfoRegion.name,
        place_to: this.delivery_to,
        place_from: item.point_from,
        unit_type: this.unit_code,
        place_across: this.delivery_to + ';Москва',
      }).then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          item.price = this.deliveryPriceInfo.price
          this.showLoader = false
          this.$emit('setTotalOfRate', { route: item, delivery_to: this.delivery_to })
        }
      });
    },
    
    getApproximatePrice(item) {
      this.deliveryPriceInfo = []
      this.$postapi(this.$address + this.$getters.getApproximatePrice.uri, {
        method: this.$getters.getApproximatePrice.name,
        v_place: item.point_from,
        v_place_to: this.delivery_to,
        v_unit_type: this.unit_code,
        v_station_id: 0,
        v_place_to_id: item.id,
        v_transporter_id: 0,
      }).then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          item.price = this.deliveryPriceInfo.price
          console.log(this.deliveryPriceInfo)
          this.showLoader = false
          this.$emit('setTotalOfRate', { route: item, delivery_to: this.delivery_to })
        }
      });
    },
    
    getAutoServiceInfo(item) {
      this.$postapi(this.$address + this.$getters.getAutoServiceInfo.uri, {
        method: this.$getters.getAutoServiceInfo.name,
        distance: item.route_val,
        unit_type: this.args.rate.unit_type,
        weight: Number(this.formdata.gds_gross_weight),
        is_vtt: Number(this.formdata.is_vtt),
        is_moscow: this.formdata.place_to_name.includes('Москва') === true ? 1 : 0,
      }).then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
            this.addService(this.deliveryPriceInfo[i], 1)
          }
          this.showServiceLoader = false
        }
      });
    },
  }
};
</script>

<template>
  <div class="auto-panel">
    <div class="auto-panel__message">
      Внимание! Все ставки даны без расчета автовывоза до склада клиента!
      <br>
      Для уточнения стоимости укажите местоположение склада
    </div>
    <div class="auto-panel__search" v-if="deliveryAccess != '0'">
      <v-autocomplete
        v-model="delivery_to"
        label="Место расположения склада"
        :loading="cityLoadingTo"
        :items="cities_to"
        :search-input.sync="searchCityTo"
        color="var(--main-orange)"
        flat
        dense
        full-width
        hide-no-data
        outlined
        clearable
        @click:clear="delivery_to = null"
        @change="getRoutes"
      />
      <div class="auto-panel__search__container" v-if="delivery_to">
        <div
          v-for="(route, index) in routes"
          :key="index"
        >
          <div v-if="route.allow_cities.includes(city_from_id)" class="auto-panel__search__container-result">
            {{route.point_from}} ➞ {{delivery_to}}, {{route.route_val}} км,
            <span v-if="showLoader === true">
            <v-progress-circular
              :size="20"
              :width="3"
              color="var(--main-orange)"
              indeterminate
            >
            </v-progress-circular>
          </span>
            <span
              v-else
              class="auto-panel__search__container-result-price"
            >
            {{route.price}} &#8381;
          </span>
          </div>
        </div>
      </div>
    </div>
    <div class="auto-panel__close-btn">
      <v-icon
        size="20"
        class="ma-2"
        @click="$emit('close')"
      >
        mdi-close
      </v-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.auto-panel {
  display: flex;
  width: 100%;
  background: #FFFFFF;
  height: 64px;
  padding: 14px 15px;
  border-radius: 4px;
  
  &__message {
    min-width: 480px;
    width: auto;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.70);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  
  &__search {
    width: calc(50% - 100px);
    margin: 0;
    position: relative;
    
    &__container {
      position: absolute;
      z-index: 100;
      top: 40px;
      
      &-result {
        padding: 4px 12px;
        border-radius: 3px;
        width: auto;
        border: 0.5px solid rgba(0, 0, 0, 0.40);
        background: #FFF;
        color: #666;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        
        &-price {
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
  
  &__close-btn {
    margin-left: auto;
  }
}
</style>

