<script>
export default {
  name: "RateConvention.vue",
  props: {
    rate: {
      type: [Object, Array]
    }
  },
}
</script>

<template>
  <div class="convention">
    <div class="convention__title">Конвенции</div>
    <v-chip
      class="convention__date"
      outlined
      color="var(--error-dark)"
      small
    >
      <v-icon left x-small color="var(--error-dark)">
        mdi-circle
      </v-icon>
      {{rate.station_name}} - до
      {{new Date(rate.convention_date_to).toLocaleDateString('ru', {
      month: 'long',
      day: 'numeric'
    }) }}
    </v-chip>
  </div>
</template>

<style scoped lang="scss">

.convention{
  background: #FFF;
  
  &__title {
    width: 100%;
    text-align: center;
  }
  
  &__date {
    margin: 5px 0;
    font-weight: 500;
    color: black !important;
  }
}

</style>
