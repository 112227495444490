<script>
import { slides } from "@/components/jsons/slides";
import SlideChineseNewYearCard from "./SlideChineseNewYearCard.vue";

export default {
  name: "SlideChineseNewYear.vue",
  components: {
    SlideChineseNewYearCard,
  },

  data() {
    return {
      ports: slides[1].ports,
      rates: slides[1].rates,
      containerIcon: require("@/assets/img/slides/container.svg"),
    };
  },
};
</script>

<template>
  <div class="chinese-newyear-slide">
    <div class="chinese-newyear-slide__wrapp">
      <div class="chinese-newyear-slide__date">
        <p class="chinese-newyear-slide__date-title">
          Китайский Новый год 2025
        </p>
        <div class="chinese-newyear-slide__dates">
          <span>29.01</span>
          <span>12.02</span>
        </div>
      </div>
      <div class="chinese-newyear-slide__advantages">
        <div class="chinese-newyear-slide__advantages-item">
          <h2 class="chinese-newyear-slide__title">
            Планируйте <br />доставку
            <span class="chinese-newyear-slide__title--orange">заранее!</span>
          </h2>
          <p class="chinese-newyear-slide__subtitle">
            Не упустите последние места<br />
            на судах до Китайского Нового года!
          </p>
        </div>
        <div class="chinese-newyear-slide__advantages-item">
          <p class="chinese-newyear-slide__advantages-title">
            Застрахуйте себя от:
          </p>
          <ul class="chinese-newyear-slide__advantages-list">
            <li class="chinese-newyear-slide__list-item">
              Задержек в доставке
            </li>
            <li class="chinese-newyear-slide__list-item">
              Повышения стоимости
            </li>
            <li class="chinese-newyear-slide__list-item">
              Отсутствия мест на судах
            </li>
          </ul>
        </div>
      </div>
      <div class="chinese-newyear-slide__bottom">
        <div class="chinese-newyear-slide__weight">
          <div class="chinese-newyear-slide__weight-item">
            <span>20FT</span>
            <img :src="containerIcon" alt="containerIcon" />
          </div>
          <div class="chinese-newyear-slide__weight-item">
            <span>40FT</span>
            <img :src="containerIcon" alt="containerIcon" />
          </div>
        </div>
        <div class="chinese-newyear-slide__ports">
          <template v-for="(port, idx) in ports">
            <div
              v-if="port.id !== 16"
              class="chinese-newyear-slide__ports-item"
              :key="idx"
            >
              <h3 class="chinese-newyear-slide__ports-item-title">
                {{ port.name }}
              </h3>
              <SlideChineseNewYearCard
                v-for="(rate, idx) in rates"
                :rate="rate"
                :port_from_id="port.id"
                :key="idx"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.chinese-newyear-slide {
  background-image: url("../../../../assets/img/slides/chinese-new-year-banner.webp");
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 1;

  &:before {
    background: linear-gradient(90deg, #182533 20%, rgba(72, 111, 153, 0) 100%);
    background-blend-mode: multiply;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__wrapp {
    max-width: 1590px;
    margin: 0 auto;
    padding: 33px 35px 27px;
    color: #fff;
    width: 100%;
  }

  &__date {
    border-radius: 22px;
    border: 3px solid var(--orange-70);
    background: rgba(37, 40, 42, 0.9);
    background-blend-mode: darken;

    padding: 18px 20px 10px;
    max-width: fit-content;

    @include font-headline-1;
    font-size: 28px;
    font-weight: 500;

    margin-bottom: 63px;

    @media (max-width: 1450px) {
        margin-bottom: 20px;
    }
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      border: 1px solid #fff;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      display: block;
      background-color: #282a2c;
      z-index: 1;

      &:first-child {
        padding-right: 30px;
      }

      &:last-child {
        padding-left: 30px;
      }
    }
  }

  &__title {
    @include font-title-1;
    font-weight: 600;
    text-shadow: 0px 0px 20px #000;
    margin-bottom: 22px;
    line-height: 60px;

    @media (max-width: 1450px) {
      font-size: 60px;
    }

    &--orange {
      font-weight: 700;
      color: #ff8614;

      @media (max-width: 1450px) {
        // font-size: 70px;
      }
    }
  }

  &__subtitle {
    @include font-headline-2;
    font-size: 28px;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 1450px) {
        font-size: 28px;
    }
  }

  &__advantages {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-end;
    margin-bottom: 20px;

    &-title {
      margin-bottom: 14px;
    }

    &-item {
      &:last-child {
        border-radius: 18px;
        border: 2px solid var(--orange-70);
        background: rgba(37, 40, 42, 0.8);
        background-blend-mode: darken;
        padding: 27px 22px 24px;

        font-size: 28px;
        font-weight: 600;
      }
    }

    &-list {
      font-weight: 500;
    }
  }

  &__list-item {
    padding-left: 20px;
    position: relative;
    font-size: 22px;
    color: var(--orange-30);

    &:before {
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      background-color: var(--orange-30);
      border-radius: 10px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0 45px;
  }

  &__ports {
    display: flex;
    gap: 0 70px;
    justify-content: space-between;
    width: 100%;

    &-item {
      flex: 1;

      &-title {
        @include font-headline-1;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        text-shadow: 0px 0px 13.187px rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__weight {
    flex-basis: 70px;
    display: flex;
    flex-direction: column;
    gap: 60px 0;
    margin-bottom: 10px;

    &-item {
      @include font-body-1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 60px;
        height: 40px;
      }
    }
  }
}</style>
