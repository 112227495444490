<script>
import BannerPreview from "../banner/BannerPreview.vue";
import { setCookie, getCookie } from "@/utils/cookie";

import {bus} from "@/router/bus";

export default {
  name: "BannerList",
  components: {
    BannerPreview,
  },

  props: {
    load: { type: Number }
  },

  data() {
    return {
      banners: [],
      bannersLimit: 3,
      currentIndex: 0,
      viewedBannerIds: []
    };
  },

  watch: {
    load(val, oldVal) {
      if (val === 1 && oldVal === 0) {
        this.showLess()
      }
    },

    "tripwires.banners": {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.banners = newVal;
          this.loadViewedBanners();
          this.setNextBannerIndex();
        }
      },
      immediate: true // Вызываем сразу при инициализации
    }
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth
    },

    notViewedBanners() {
      return this.banners.filter(banner => !this.isViewed(banner.id));
    },

    getABTest() {
      return +this.$_getsetting('ab_test');
    },

    sortedBanners() {
      return this.banners.slice().sort((a, b) => {
        const aViewed = this.isViewed(a.id);
        const bViewed = this.isViewed(b.id);

        if (aViewed && !bViewed) return 1;
        if (!aViewed && bViewed) return -1;
        return 0;
      });
    },

    currentBanner() {
      return this.sortedBanners[this.currentIndex];
    },

    visibleBanners() {
      return this.sortedBanners.slice(0, 3);
    },
  },

  mounted() {
  },

  inject: ['tripwires'],

  methods: {
    isViewed(bannerId) {
      return this.viewedBannerIds.includes(bannerId)
    },

    setNextBannerIndex() {
      const maxIndex = this.notViewedBanners.length > 2 ? 2 : this.notViewedBanners.length - 1;
      let nextIndex = parseInt(getCookie('bannerIndex') || 0, 10);

      nextIndex = (nextIndex + 1) % (maxIndex + 1);
      this.currentIndex = nextIndex;
      setCookie('bannerIndex', nextIndex, 1); // 1 день
      if (this.notViewedBanners.length !== 0) {
        this.openFullBanner()
      }
    },

    loadViewedBanners() {
      const viewedBannerIdsString = getCookie('viewedBannerIds');
      if (viewedBannerIdsString) {
        this.viewedBannerIds = JSON.parse(viewedBannerIdsString);
      }
    },

    openFullBanner() {
      bus.$emit('openFullBanner', this.currentBanner)
    },

    showMore() {
      this.bannersLimit > this.banners.length ? null : this.bannersLimit = this.bannersLimit + 2
    },

    showLess(){
      this.bannersLimit = 3
    },
  },
};
</script>

<template>
  <div :class="['banner-list', { 'padding': getABTest === 0 }]">
    <p :class="['banner-list__title', getABTest ? 'bold' : 'normal']">Преимущества Vedexx</p>
    <div class="banner-list__banners" v-if="banners.length > 0">
      <BannerPreview
        v-for="banner in sortedBanners.slice(0, this.bannersLimit)"
        :key="banner.id"
        :banner="banner"
        :read="isViewed(banner.id)"
      />
    </div>
    <button
      class="banner-list__btn banner-list__btn--show-more"
      v-if="bannersLimit < banners.length"
      @click="showMore"
    >
      показать еще
  </button>
    <button
      class="banner-list__btn banner-list__btn--show-less"
      @click="showLess"
      v-else-if="banners.length > 3"
    >
      скрыть
    </button>
  </div>
</template>

<style scoped lang="scss">
.banner-list {
  overflow-y: auto;
  border-radius: 8px;

  @media (max-width: $tablet-width){
    padding: 24px 0;
  }

  &.padding {
    padding: 24px 12px;
    overflow-y: auto;

    @media (max-width: $tablet-width) {
      padding: 24px 0 !important;
    }
  }

  &__title {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 16px;

    &.bold {
      @include font-headline-3;
    }

    &.normal {
      @include font-body-2;
    }

    @media (max-width: $tablet-width) {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.16px;
    }
  }

  &__banners {
    display: grid;
    gap: 8px 24px;

    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  &__btn{
    color: var(--blue-70);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-decoration: underline;
    margin-top: 16px;
    transition: .2s;

    &:hover{
      text-decoration: none;
    }

    @media (max-width: $tablet-width){
      font-size: 12px;
    }
  }
}
</style>