<script>
export default {
  name: 'RateTooltip',
  props: ['parentElement'],
  computed: {
  }
};
</script>

<template>
  <div class="custom-tooltip">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.custom-tooltip {
  position: absolute;
  width: 318px;
  border-radius: 6px;
  background: #1E202CE5;
  padding: $size-8 $size-12 $size-16;
  z-index: 9999 !important;
  color: white;
  line-height: 20px;
  box-shadow: 0px 11px 20px 0px #0510371A;
  
  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'%3E%3Cpath fill='%231E202CE5' d='M19.146 7.639c-.63-.901-1.637-.884-2.236.038L6.09 24.323C5.491 25.245 5.9 26 7 26h23c1.1 0 1.483-.737.854-1.639z'/%3E%3C/svg%3E");
    position: absolute;
    width: 25px !important;
    height: 30px !important;
    top: calc(50% - 15px);
    transform: rotate(-90deg);
    left: -15px;
    bottom: 0;
    z-index: 21;
    
    @media(max-width: $tablet-width) {
      content: '';
    }
  }
}
</style>
