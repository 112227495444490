export const complex_spec =
    {
        name: 'ComplexRatesParams',
        method: 'getComplexRateList',
        unit_type_element: {
            id: 0,
            caption: "Тип оборудования",
            name: "unit_code",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: true,
            chips: true,
            reference: 'ClientVDRefUnitTypes',
        },
        
        place_from_element: {
            id: 0,
            caption: "Место отправки",
            name: "place_from",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: true,
            chips: true,
            reference: 'lgst_place_from_wg',
        },
        
        place_to_element: {
            id: 0,
            caption: "Место доставки",
            name: "place_to",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: true,
            chips: true,
            reference: 'lgst_place_to_wg',
        },
        
        alaram_class_element: {
            id: 0,
            caption: "Класс опасности",
            name: "alaram_class",
            items: [],
            keyfield: "id",
            listfield: "name",
            default: 0,
            multiple: true,
            chips: true,
            reference: 'lgst_alarm_class',
        },
        
        on_date_element: {
            caption: "Дата выхода",
            name: "on_date",
        },
        methods: {
            extractValues(data){
                let result = {}
                if (data.controls.name == data.element) {
                    for (let element of Object.keys(data.controls)) {
                        if (data.value[data.controls[element].name] != undefined) {
                            if (data.value[data.controls[element].name].length > 0) {
                                if (data.value[data.controls[element].name][0][data.controls[element].keyfield] != undefined) {
                                    for (let i = 0; i < data.value[data.controls[element].name].length; i++) {
                                        result[data.controls[element].name] = data.value[data.controls[element].name][i][data.controls[element].keyfield]
                                    }
                                } else {
                                    result[data.controls[element].name] = data.value[data.controls[element].name]
                                }
                            }
                        }
                    }
                }
                result.method = data.controls.method
                return { name: data.controls.name, values: result }
            },
        },
}
