<script>
import { reachYMGoal } from "@/utils/metrika";
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      displayedVideoCount: 3,

      isOpenVideos: false,
      message: "Посмотреть все",

      videoInstructions: [
        {
          id: 7,
          title: "Регистрация на Vedexx",
          subtitle: "Создание учётной записи и добавление информации",
          videoUrl: "I1_registration.mp4",
          poster: "instruction_cover1.webp",
          href: "https://rutube.ru/play/embed/b7f70c45e8a424a3be006c15e9b40c5d/",
        },
        {
          id: 8,
          title: "Работа со ставками",
          subtitle: "Поиск и фильтрация ставок",
          videoUrl: "I2_rates.mp4",
          poster: "instruction_cover2.webp",
          href: "https://rutube.ru/play/embed/d75bd5f839069d9223f6ffbe29448623/",
        },
        {
          id: 9,
          title: "Оформление заявки на буккинг",
          subtitle: "Заполнение формы для создания заявки",
          videoUrl: "I3_add_rate.mp4",
          poster: "instruction_cover3.webp",
          href: "https://rutube.ru/play/embed/cca71ecbdeb3907329610f1be504459c/",
        },
        {
          id: 10,
          title: "Управление личным кабинетом Vedexx",
          subtitle: "Работа с профилем и личным кабинетом",
          videoUrl: "I4_archive.mp4",
          poster: "instruction_cover4.webp",
          href: "https://rutube.ru/play/embed/99055a8dcfcaef8acadf911640539afe/",
        },
        {
          id: 11,
          title: "Подписка по маршруту",
          subtitle: "Как отслеживать изменения в ставках",
          videoUrl: "I5_subscription.mp4",
          poster: "instruction_cover5.webp",
          href: "https://rutube.ru/play/embed/6241b11c609d61abd0e8ddd5b5326038/",
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("load", this.handleResize)
    this.handleResize()

    this.tapToInstruction()
  },

  computed: {
    videosList() {
      return this.videoInstructions.slice(0, this.displayedVideoCount)
    },
  },

  methods: {
    handleResize() {
      if (this.windowWidth < 1201 && this.windowWidth > 743) {
        this.displayedVideoCount = 2
      } else if (this.windowWidth < 744) {
        this.displayedVideoCount = 1
      } else {
        this.displayedVideoCount = 3
      }
    },

    tapToInstruction() {
      window.addEventListener("message", (event) => {
        try {
          let message = JSON.parse(event.data)
          let iframeList = document.querySelectorAll("iframe")
          switch (message.type) {
            case "player:changeState":
              iframeList.forEach((iframe) => {
                if (iframe.contentWindow === event.source) {
                  this.addMetrics(iframe.id)
                }
              });
              break;
          }
        } catch (e) {return}
      });
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    openVideos() {
      if (!this.isOpenVideos) {
        this.displayedVideoCount = this.videoInstructions.length
        this.isOpenVideos = true
        this.message = "Скрыть"
      } else {
        this.handleResize()
        this.isOpenVideos = false
        this.message = "Посмотреть все"
      }
    },
  },
};
</script>

<template>
  <div class="video-list g-row">
    <div
      class="video-list__card g-col-lg-4 g-col-md-4 g-col-sm-4 g-col-xs-2"
      v-for="(video, index) in videosList"
      :key="video.id"
    >
      <div class="iframe-wrapper">
        <iframe
          class="video-list__card-frame"
          :id="'Instruction-' + (index + 1)"
          :src="video.href"
          frameBorder="0"
          allow="clipboard-write"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        ></iframe>
      </div>
      <div class="video-list__card__ttl">{{ video.title }}</div>
      <div class="video-list__card__sub-ttl">{{ video.subtitle }}</div>
    </div>
    <div
      class="video-list__show-all"
      :class="
        isOpenVideos ? 'video-list__show-all-up' : 'video-list__show-all-down'
      "
      @click="openVideos"
    >
      {{ message }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.video-list {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 60px;
  &__card {
    margin-bottom: $size-32;
    border: 0.5px solid #9e9e9e;

    &:last-child {
      margin-bottom: $size-24;
    }

    @media (min-width: $desktop-width) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &-frame {
      position: relative;
      width: 100%;
      height: 220px;
    }

    &__ttl {
      @include font-headline-2;

      color: #050505;
      margin: 10px 20px 5px;
    }

    &__sub-ttl {
      @include font-subtitle-regular;

      letter-spacing: 0.25px;
      color: #3e3e3e;
      margin: 0 20px 15px;
    }
  }

  &__show-all {
    display: flex;
    width: 100%;
    color: black;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    z-index: 2;

    @include right-gap;
    @include font-description-semibold-0;

    &:hover {
      text-decoration: underline;
    }

    &-down {
      display: flex;

      &:after {
        content: '';
        text-align: center;
        line-height: 10px;
        background: url('../../assets/img/icons/mdi/arrow-down-2-duotone.svg')  no-repeat;
        background-size: 100%;
        width: 20px;
        height: 19px;
      }
    }

    &-up {
      display: flex;

      &:after {
        content: '';
        text-align: center;
        line-height: 10px;
        background: url('../../assets/img/icons/mdi/arrow-up-2-duotone.svg') no-repeat;
        background-size: 100%;
        width: 20px;
        height: 19px;
      }
    }
  }
}
</style>
