<script>
import RateConvention from "../rate-card/RateConvention.vue";

export default {
  name: "RateInfoCircles",
  components: {RateConvention},

  props: {
    rate: {
      type: Object,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$emit('block-mounted', this.$refs.circleBlock);
    });
  },

  computed: {
    getClientId() {
      return Number(this.$_getsetting('client_id'))
    },
  }
}
</script>

<template>
  <div class="card-logos" ref="circleBlock">
    <v-tooltip top color="black" transition="slide-y-reverse-transition" content-class="card-logos__tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="rate.is_soc == 1"
          class="card-logos__circle"
          v-bind="attrs"
          v-on="on"
        >
          <img src="blue.png"/>
        </div>
      </template>
      <span class="mt-2">Арендованное оборудование</span>
    </v-tooltip>

    <v-tooltip top color="black" transition="slide-y-reverse-transition" content-class="card-logos__tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="rate.is_gondola == 1"
          class="card-logos__circle"
          v-bind="attrs"
          v-on="on"
        >
          <img src="../../assets/img/gondola.png" alt="Возможна отправка в полувагоне">
        </div>
      </template>
      <span>Возможна отправка в полувагонах
          <br>
              (срок может быть увеличен)
        </span>
    </v-tooltip>

    <v-tooltip top color="black" transition="slide-y-reverse-transition" content-class="card-logos__tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="rate.is_not_direct_route == 1"
          class="card-logos__circle"
          v-bind="attrs"
          v-on="on"
        >
          <img style="padding: 1px" src="../../assets/img/crane.png" alt="Роутинг не прямой">
        </div>
      </template>
      <span>
          Роутинг не прямой. Заявлена
          <br>
          перевалка на пути следования
        </span>
    </v-tooltip>

    <v-tooltip top color="black" transition="slide-y-reverse-transition" content-class="card-logos__tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="rate.is_hot == 1"
          class="card-logos__circle"
          v-bind="attrs"
          v-on="on"
        >
          <img class="card-logos__img" src="@/assets/img/icons/mdi/mdi-lightning-bolt.svg" alt="lightning-bolt-icon">
        </div>
      </template>
      <span class="mt-2">Горячее предложение</span>
    </v-tooltip>

    <v-tooltip top color="black" transition="slide-y-reverse-transition" content-class="card-logos__tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="rate.is_agent === 1 && (getClientId == 0 || getClientId == -1)"
          class="card-logos__circle"
          v-bind="attrs"
          v-on="on"
        >
          <img src="../../assets/img/agent.png" width="14px" alt="Агентское предложение" title="Агентское предложение">
        </div>
      </template>
      <span class="mt-2">Агентское предложение</span>
    </v-tooltip>

    <v-tooltip right color="white" transition="slide-y-reverse-transition" content-class="card-logos__tooltip-info">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="rate.is_convention === 1"
          class="card-logos__circle card-logos__info-circle"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            size="16px"
            class="flex"
            color="var(--dark-orange)"
          >
            mdi-exclamation-thick
          </v-icon>
        </div>
      </template>
      <RateConvention :rate="rate"/>
    </v-tooltip>
  </div>
</template>

<style scoped lang="scss">
.card-logos {
  display: flex;
  //z-index: 100;

  @media (max-width: 600px) {
    top: -15px;
  }

  &__tooltip {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.238px;
    height: auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    min-height: 45px;

    span {
      vertical-align: middle;
    }
  }

  &__tooltip-info {
    color: black;
    border-radius: 6px;
    border: 0.5px solid rgba(108, 108, 108, 0.74);
    background: #FFF;
    opacity: 1;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.16);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.238px;
    padding-bottom: 4px;
    height: auto;
    text-align: center;
    display: flex;
    min-height: 45px;

    &::before {
      content: '' !important;
    }
  }

  &__circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #FFF;
    padding: 1px;
    margin: 0 8px 0 0;
    cursor: pointer;
    border: 1px solid var(--grey-50);
    display: flex;

    //&:hover {
    //  filter: drop-shadow(0px 1px 1px var(--grey-20));
    //}

    > img {
      width: 17px;
      margin: auto;
      height: auto;
    }
  }

  &__info-circle {
    border: 1px solid var(--dark-orange);
    padding-left: 6px;
  }
}

.card-logos__img {
  min-width: 28px;
  min-height: 28px;
}
</style>
