<script>
import ReviewList from "./reviews/ReviewList.vue";
import MediaArticleList from "./mass-media/MediaArticleList";
import VideoSlider from "./video-slider/VideoSlider.vue";
import VideoInstructionList from "./VideoInstructionList";
import FAQBlock from "./FAQBlock";

import {bus} from "../../router/bus";
export default {
  components: {
    ReviewList,
    MediaArticleList,
    VideoSlider,
    VideoInstructionList,
    FAQBlock,
  },
  
  props: {
    videos: {
      type: Array,
      required: true
    }
  },
  
  data () {
    return {
      news: [],
      showFullReview: false,
      review: [],
    }
  },
  
  mounted() {
    bus.$on("showfullText", (data) => {
      this.showFullReview = true
      this.review = data
    });

    bus.$emit('scrollToElements', (this.$refs))
  }
}
</script>

<template>
  <div class="main-info">
    <div class="main-info__h2" ref="reviews">Отзывы клиентов</div>
    <ReviewList id="reviews" class="main-info__reviews"/>
    
    <div class="row justify-space-between align-center">
      <div class="main-info__h2 ml-3">СМИ о нас</div>
      <router-link to="/MassMedia" class="main-info__page-link">смотреть все</router-link>
    </div>

    <MediaArticleList class="mb-16"/>
  
    <VideoSlider class="main-info__video-slider" ref="webinar"/>
  
    <div class="main-info__h2" ref="videoInstructions">Видео-инструкции</div>
    <VideoInstructionList id="videoInstructions" class="main-info__instructions"/>
  
    <div class="main-info__h2 mt-10">Часто задаваемые вопросы</div>
    <FAQBlock id="whatVedexxIs" class="mb-16"/>
    
    <div ref="whatVedexxIs" class="main-info__h2 main-info hide-mobile">Что такое Vedexx?</div>
    <div class="main-info__defenition left-20 g-row hide-mobile">
      <div class="g-col-md-8 g-col-lg-7 g-col-sm-10 g-col-xs-2">
        Vedexx - <span class="main-info__defenition-p">это первый IT-инструмент на рынке ВЭД,</span>
        который значительно упрощает жизнь участников международной логистики.
      </div>
    </div>
    
    <div class="main-info__h3 pt-2 hide-mobile">А именно:</div>
    <ul class="main-info__list left-20 hide-mobile">
      <li>предоставляет важную информацию своевременно, быстро и точно </li>
      <li>продает и оказывает услуги логистики и таможенного оформления без посредников</li>
      <li>повышает качество предоставленной услуги </li>
    </ul>
  
    <div class="main-info__h3 hide-mobile pt-2">Новый подход к логистике, построенный на принципах </div>
    <div class="main-info__rectangle-list g-row hide-mobile">
      <div class="main-info__rectangle-list__item">высокой скорости</div>
      <div class="main-info__rectangle-list__item">объективности</div>
      <div class="main-info__rectangle-list__item">точности современных цифровых технологий</div>
    </div>
    
    <div class="main-info__rectangles-line g-row hide-mobile">
      <div class="main-info__rectangles-line__dark g-col-sm-8 g-col-md-6 g-col-lg-6 g-col-xs-2">IT-платформа непрерывно анализирует большое количество вводных данных</div>
      <div class="main-info__rectangles-line__light g-col-sm-8 g-col-md-6 g-col-lg-6 g-col-xs-2">
        <ul>
          <li>ставки морских линий и жд-операторов, </li>
          <li>расписание судов и поездов, </li>
          <li>наличие контейнерного оборудования в стоках, </li>
          <li>загруженность терминалов и станций, </li>
          <li>риски возникновения задержек</li>
          <li>прогнозирует сроки доставки и прочее</li>
        </ul>
      </div>
    </div>
    
    <div class="main-info__h3 hide-mobile pt-2">3 качества логистики Vedexx</div>
    <div class="main-info__h3-orange left-2 hide-mobile">1. Организованная</div>
    <div class="main-info__text left-20 hide-mobile">
     <p>Каждая транспортная компания имеет широкий спектр специалистов. Каждый из экспертов обладает информацией и компетенциями, которыми не обладают прочие.</p>
  
     <p>Взаимодействие между данными специалистами подразумевает частичную недоговоренность и потерю доли важной информации по разным причинам. Чтобы этого не происходило, мы создали Vedexx, на платформе которого аккумулируется вся необходимая информация.</p>
    </div>
    <div class="main-info__h3-orange hide-mobile">2. Систематизированная</div>
    <div class="main-info__text left-20 hide-mobile">
      <p>На основе организации информации мы выстроили систему, которая позволяет:</p>
    
      <ul style="list-style-type: none">
        <li>- своевременно подключаться к этапам перевозки всех необходимых специалистов.</li>
        <li> - выстроить порядок передачи ответственности между экспертами.</li>
        <li>- контролировать действия сотрудников</li>
        <li>- гладко проходить все этапы перевозки</li>
      </ul>
    </div>
    <div class="main-info__h3-orange hide-mobile">3. Дисциплинированная </div>
    <div class="main-info__text left-20 hide-mobile">
      <p>Используемая в международных перевозах информация...</p>
  
      <ul style="list-style-type: none">
        <li>- Собрана, обработана и находится в удобном доступе для пользователя услуг</li>
        <li>- Доступна в любой момент времени</li>
        <li>- Отражает всю информацию о подрядчиках</li>
        <li>- Отражает качество работы подрядчиков и сотрудников транспортных компаний online</li>
        <li> - Снижает зависимость общей работы от отдельных сотрудников, при этом повышает ответственность сотрудников за этапы и сроки</li>
        </ul>
    </div>
    
<!--    <div class="main-info__quote">-->
<!--      <div class="main-info__quote-text left-20">От информации к действиям</div>-->
<!--    </div>-->
<!--    <div class="main-info__quote-container"></div>-->
    
    <div class="main-info__h2 pt-2">Чем Vedexx помогает участникам ВЭД</div>
<!--    <div class="main-info__bkrgd-block"></div>-->
<!--    <div class="main-info__photo-containers"></div>-->
    
    <div class="main-info__number">01</div>
    <div class="main-info__h3 left-20">Анализирует рынок</div>
    <div class="main-info__h4 left-20 g-col-xs-2 ">
      Vedexx поможет за 5 секунд провести анализ рынка.
      <br/> Вы можете сравнить и выбрать лучшие условия
    </div>
    
    <div class="main-info__info-cards g-row left-20 hide-mobile">
      <div class="main-info__info-cards-card g-col-sm-3 g-col-md-3 g-col-lg-2 g-col-xs-2">
        <img class="main-info__info-cards-card-icon" src="../../assets/img/icons/mdi/mdi-hand-coin.svg" alt="mdi-hand-coin">
        <div class="main-info__h4 mt-3">Конкретная стоимость перевозок в заданных параметрах</div>
        <div class="main-info__info-cards-card-line"></div>
      </div>
      
      <div class="main-info__info-cards-card g-col-sm-3 g-col-md-3 g-col-lg-2 g-col-xs-2">
        <img class="main-info__info-cards-card-icon" src="../../assets/img/icons/mdi/mdi-chart-line.svg" alt="mdi-hand-coin">
        <div class="main-info__h4 mt-3">Аналитика сроков доставки</div>
        <div class="main-info__info-cards-card-line"></div>
      </div>
    </div>
  
    <div class="main-info__number">02</div>
    <div class="main-info__h3 left-20">Предоставляет информацию</div>
    <div class="main-info__h4 left-20 hide-mobile">За 5 секунд предоставляет полноценную информацию по:</div>
    <div class="main-info__horizontal-list g-col-lg-8 left-20">
      <ul class="g-col-lg-8 g-col-md-9">
        <li>● прямым подрядчикам</li>
        <li>● заданным маршрутам</li>
        <li>● срокам доставки</li>
        <li>● наличию оборудования</li>
        <li>● местам на судне</li>
        <li>● расписанию выхода судов</li>
        <li>● множеству направлений</li>
      </ul>
    </div>
    
    <div class="main-info__number">03</div>
    <div class="main-info__h3 left-20">Помогает</div>
    <div class="main-info__h4 left-20 hide-mobile">Vedexx вам поможет:</div>
    <ul class="main-info__list left-20">
      <li>Осуществить международную перевозку</li>
      <li>Не переплачивать за логистику</li>
      <li>Компенсировать расходы, в случае порчи груза</li>
      <li>Своевременно получить бухгалтерские документы</li>
      <li>Решить вопросы с финансированием</li>
      <li>Снизить, либо вовсе избавиться от дополнительных расходов</li>
      <li>Совершить своевременные и верные действия, в случае непредвиденных обстоятельств</li>
      <li>Получать полноценное информирование</li>
      <li>Доставить товар в прогнозируемые сроки</li>
      <li>Отправить груз без задержек</li>
    </ul>
    
    <div class="main-info__quote-large hide-mobile">
      <p class="main-info__quote-large-text">
        Логистика не может быть совершенной. И VEDEXX не гарантирует волшебства. Но, логистика может быть
        <span class="main-info__defenition-p">организованной, дисциплинированной и систематизированной.</span>  </p>
      <p class="main-info__quote-large-text">Полная и своевременно полученная информация - это 90% успеха. И 10% - это верные и своевременные действия.</p>
    </div>
    <div class="main-info__quote-large-container hide-mobile"></div>
    
    <v-dialog
      v-model="showFullReview"
      class="g-col-lg-6  g-col-sm-10 g-col-xs-2"
      width="auto"
    >
     <v-card
      class="g-col-lg-6 g-col-md-8 g-col-sm-10 g-col-xs-2"
     >
       <v-card-title>
        <v-avatar v-if="review.img" size="60px" color="darken-3 mr-4">
          <v-img
            class="elevation-6"
            alt=""
            :src="review.img"
          ></v-img>
        </v-avatar>
          
          <v-avatar v-else size="60px" color="darken-3 mr-4">
          <v-img
            class="elevation-6"
            alt=""
            src='../../assets/img/user.jpg'
          ></v-img>
        </v-avatar>
        <span class="text-h6 font-weight-bold">{{review.author_company}}</span>
      </v-card-title>
  
      <v-card-text class="text-h5 font-italic main-info__review-text">
        «{{review.text}}»
      </v-card-text>
       
       <v-divider class="mx-6 mb-2"></v-divider>
  
      <v-card-actions class="ml-2 font-weight-medium">
        {{review.author_name}}
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.main-info {
  
  &__video-slider {
    position: relative;
    margin-top: 40px;
    top: 30px;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    height: 65%;
  }
  
  &__page-link {
    margin: 22px 28px 22px 0;
    color: #000000;
    text-decoration: none;
    align-items: center;
    display: flex;
    transition-duration: 0.2s;

    @include font-headline-3;
    
    @media (max-width: $mobile-width) {
      margin: 28px 14px 22px 0;
      font-size: $size-16;
    }
    
    &:hover {
      text-decoration: underline;
    }
    
    &:after {
      content: '';
      text-align: center;
      padding: 5px 6px 0;
      line-height: 20px;
      background: url('../../assets/img/icons/mdi/arrow-right-solid.svg') no-repeat;
      width: 24px;
      height: 24px;
      background-size: 100%;
      margin-left: 8px;
    }
  }
  
  &__h2 {
    @include font-headline-1;

    margin: 40px 0 30px;
    letter-spacing: 0.01em;
    color: #000000;
  }
  
  &__defenition {
    @include font-body-1;

    letter-spacing: 0.01em;
    width: auto;
    position: relative;
    margin-bottom: $size-32;
  
    &-p {
      @include font-body-1;

      box-decoration-break: clone;
      background: var(--main-orange);
      display: inline;
      box-shadow: 0.250px 0 0 var(--main-orange), -0.250px 0 0 var(--main-orange);
    }
  }
  
  &__h3 {
    @include font-headline-2;

    color: #000000;
    margin: $size-12 0;
    letter-spacing: 0.01em;
    
    &-orange {
      @include font-headline-2  ;

      color: var(--main-orange);
      margin: 25px 0;
      letter-spacing: 0.01em;
    }
  }
  
  &__h4 {
    @include font-body-2;

    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 10px;
    display: block;
  }
  
  &__text {
    @include font-body-2;

    letter-spacing: 0.01em;
    color: #000000;
    width: 70%;
    max-width: 780px;
  }
  
  &__list {
    @include font-subtitle-regular;

    letter-spacing: 0.01em;
    color: #000000;
    line-height: 181.1%;
    list-style-type: none;
    
    > li {
      padding-left: 2rem;
      margin-top: 8px;
      background-image: url("../../assets/img/li_star.png");
      background-position: 0 0;
      background-size: 1.6rem 1.6rem;
      background-repeat: no-repeat;
      
      &:last-child {
        margin-bottom: $size-32;
      }
    }
  }
  
  &__quote-container {
    width: 50vw;
    height: 138px;
    position: relative;
    padding: 40px 0 0 15%;
    margin: 80px 0 80px 0;
  }
  
  &__quote {
    background: var(--main-bg-color);
    width: calc(50vw + 40%);
    height: 138px;
    position: absolute;
    margin-left: 0;
    left: -25%;
    margin-top: 40px;
    transform: translateX(-25%);
    filter: drop-shadow(0px 3.795px 3.795px rgba(0, 0, 0, 0.25));
    //margin: 80px 0 80px 0;
    padding: 40px 0 0 40vw;
    
    @media (max-width: $tablet-width) {
      width: calc(100% - 32px);
      padding: 40px 0 0 60vw;
    }
    
    @media (max-width: 743px) {
      width: 135%;
      padding: 40px 0 0 60vw;
    }
    
    &::after {
      display: block;
      content: '';
      width: 100px;
      height: 138px;
      background: var(--main-bg-color);
      position: absolute;
      bottom: 0;
      right: 50px;
      transform-origin: right;
      transform: translateX(100%) skewX(145deg);
    }
    
    &-text {
      @include font-headline-2;

      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      position: absolute;
      left: 10%;
    }
  }
  
  &__rectangle-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: $size-12;
    
    &__item {
      @include font-body-1;

      background: rgba(255, 255, 255, 0.95);
      text-align: center;
      border: 1px solid #B7B7B7;
      padding: $size-24 $size-40;
      margin: $size-12 0;
      align-items: center;
      letter-spacing: 0.01em;
      color: #000000;
      @include right-gap;
      
      @media (max-width: $mobile-width) {
        padding: $size-24 $size-12;
      }
    }
  }
  
  &__rectangles-line {
    width: 100%;
    margin: $size-40 0;
    
    &__dark {
      @include font-headline-1;

      height: 190px;
      background: var(--main-bg-color);
      letter-spacing: 0.01em;
      color: #FFFFFF;
      padding: 0 20px;
      align-items: center;
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      text-align: center;
      justify-content: center;
      
      @media (max-width: $mobile-width) {
        height: auto;
        padding: $size-16 $size-20;
      }
    }
    
    &__light {
      @include font-body-1;

      height: 190px;
      flex-wrap: wrap;
      padding: 20px 35px;
      background: #FFFFFF;
      border: 1px solid #B7B7B7;
      line-height: 156.1%;
      letter-spacing: 0.01em;
      color: #000000;
      align-items: center;
      align-content: center;
      display: flex;
      position: relative;
      justify-content: center;
      
      @media (max-width: $mobile-width) {
        height: auto;
      }
    }

    li{
      @include font-body-2;
    }
  }
  
  &__bkrgd-block {
    right: -16%;
    position: absolute;
    width: 62%;
    height: 458px;
    background: var(--main-bg-color);
  }
  
  &__photo-containers {
    background-image: url("../../assets/img/main_page_container.png");
    width: 416px;
    height: 262px;
    position: absolute;
    right: -16%;
    margin-top: -10%;
  }
  
  &__number {
    @include font-headline-0;

    letter-spacing: 0.01em;
    color: #F2A435;
  }
  
  &__info-cards {
    display: flex;
    margin: 20px 0 32px;
    
    &-card {
      background: #FFFFFF;
      border: 0.5px solid #BCBCBC;
      position: relative;
      height: auto;
      padding: 25px 55px;
      margin-right: 25px;
      
      &-line {
        width: 43.49px;
        height: 1px;
        margin-bottom: 30px;
        margin-top: 8px;
        border: 0.820388px solid rgba(70, 70, 70, 0.74);
      }
    }
  }
  
  &__horizontal-list {
    @include font-subtitle-regular;

    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    width: 100%;
    line-height: 141.1%;
    letter-spacing: 0.01em;
    list-style-type: circle !important;
    margin-bottom: $size-32;
    
    @media (max-width: $tablet-width){
      width: 100%;
    }
    
    > ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 0;
      list-style-type: none;
      padding: 0;
      
      @media (max-width: $tablet-width){
        display: block;
        max-width: 90%;
      }
      
      > li {
        display: inline;
        margin: 0 50px 0 0;
        padding: 0 0;
        background-position: 0 0;
        background-size: 1.6rem 1.6rem;
        background-repeat: no-repeat;
        
        @media (max-width: $tablet-width){
          display: block;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  
  &__quote-large-container {
    width: 50vw;
    height: 370px;
    position: relative;
    padding: 40px 0 0 15%;
    margin: 80px 0 80px 0;
  }
  
  &__quote-large {
    background: var(--main-bg-color);
    position: absolute;
    margin-left: 0;
    left: -25%;
    margin-top: 40px;
    transform: translateX(-25%);
    filter: drop-shadow(0px 3.795px 3.795px rgba(0, 0, 0, 0.25));
    height: 370px;
    padding: 75px 100px 0 50vw;
    
    @media (max-width: $mobile-width) {
      display: none;
    }
    
    @media (max-width: $tablet-width){
      width: calc(100% - 64px);
      left: -32px;
      transform: none;
      padding: 75px 32px 0 32px;
    }
    
    &::after {
      display: block;
      content: '';
      width: 100px;
      height: 370px;
      background: var(--main-bg-color);
      position: absolute;
      bottom: 0;
      right: 50px;
      transform-origin: right;
      transform: translateX(100%) skewX(165deg);
      z-index: 0;
      
      @media (max-width: $mobile-width){
        height: 100%;
        width: 70px;
        transform: translateX(100%) skewX(177deg);
        right: 20px;
      }
    }
    
    &-text {
      @include font-body-1;
      
      line-height: 143.1%;
      letter-spacing: 0.025em;
      color: #FFFFFF;
      z-index: 2;
      
      @media (max-width: $mobile-width) {
        font-size: 18px;
      }
    }
  }
}

.hide-mobile {
  @media (max-width: $mobile-width) {
    display: none;
  }
}

.left-20 {
  @include left-gap;
  position: relative;
  
  @media (max-width: $tablet-width) {
    left: 0;
  }
}

.main-info__info-cards-card-icon{
  width: 44px;
  height: 44px;
}
</style>
