<script>
export default {
  name: "TransporterCard.vue",

  props: {
    args: {
      type: [Object, Array],
    },
  },

  data() {
    return {
      defaultLogo: require("@/assets/img/icons/default-logo-line.svg"),
    };
  },

  methods: {
    handleError(e) {
      e.target.src = this.defaultLogo
    },
  },
}
</script>

<template>
  <div class="transporter-card">
    <div class="transporter-card__logo">
      <img
        @error="handleError"
        :alt="args.name"
        :title="args.name"
        :src="`/companies-logo/${args.logo_name}.png`"
      />
      <v-btn color="black" @click="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <h3 class="transporter-card__ttl">Перевозчик "{{ args.name }}"</h3>
    <div class="transporter-card__text">{{ args.info }}</div>

    <div class="transporter-card__sub-ttl">Группа компаний</div>
    <div class="transporter-card__text">{{ args.company_group_name }}</div>

    <div class="transporter-card__sub-ttl">Год основания</div>
    <div class="transporter-card__text">{{ args.foundation }}</div>

    <div class="transporter-card__sub-ttl">
      Среднее количество перевозимых теусов в месяц за прошедший год
    </div>
    <div class="transporter-card__text">{{ args.teu_per_month }}</div>
  </div>
</template>

<style scoped lang="scss">

.transporter-card {
  padding: 40px;
  background: white;
  color: #000000;

  @media (max-width: 600px) {
    padding: 30px 20px 20px;
  }

  &__logo {
    height: 60px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      height: 50px;
    }

    > img {
      height: 60px;

      @media (max-width: 600px) {
        height: 50px;
      }
    }
  }

  &__ttl {
    font-weight: 600;
    font-size: 23.0047px;
    line-height: 31px;
    letter-spacing: 0.01em;
    margin: 10px 0;

    @media (max-width: 600px) {
      font-size: 20px;
      margin: 5px 0;
    }
  }

  &__sub-ttl {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    margin-bottom: 12px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}

</style>
